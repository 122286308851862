import { useEffect, useState } from "react";
import { IconIndicator } from "./IconIndicator";

function IconPill({
    iconId, iconText, iconName, pillText, iconColor, iconSize,
    onPillClick, onCloseClick, className = '',
    defaultSelected = false, asOutline=false, closable=false, clickable=true, animated=false
}) {

    const [selected, setSelected] = useState(false);
    const [fontSize, setFontSize] = useState('initial');
    const [fontIconSize, setFontIconSize] = useState('initial');

    useEffect(() => {
        setSelected(defaultSelected);
        switch(iconSize) {
            case 'lg': setFontIconSize('32px'); setFontSize('1.6em'); break;
            case 'md': setFontIconSize('24px'); setFontSize('initial'); break;
            case 'sm': setFontIconSize('18px'); setFontSize('0.8em'); break;
        }
    }, [defaultSelected]);

    const onClickHandler = (evt) => {
        if (animated && ! selected) {
            setSelected(! selected);
        }
        if(! clickable) {
            console.log('Not clickable... Duhhh');
            return;
        }
        if(evt.target.id?.startsWith('close-indicator')) {
            onCloseClick && onCloseClick(iconId);
        } else {
            onPillClick && onPillClick(evt);
        }
    };

    return (
    <span onClick={onClickHandler} style={{backgroundColor:iconColor + '99', width:'fit-content'}}
        className={
            "d-flex border-0 align-self-start position-relative rounded rounded-pill "
            + (clickable ? 'cursor-pointer ' : '')
            + className}>
        <IconIndicator
            iconText={iconText}
            iconName={iconName}
            iconColor={iconColor}
            iconSize={iconSize}
            iconOnClick={onPillClick}
            asOutline={asOutline}/>
        { pillText && <span className={
            "my-auto text-white"
            + (animated ? ' pill-animated' : (' ml-1 ' + (closable ? ' mr-1' : ' mr-3') ))
            + (selected ? ' pill-active' : ' pill-inactive')
            + (clickable ? ' cursor-pointer' : '')}
            style={{whiteSpace:'nowrap',fontSize}}>{pillText}</span>}
        {closable &&
            <span id={'close-indicator' + iconId}
                style={{opacity:'60%',fontSize:fontIconSize}}
                className="material-icons-round closable text-white mr-2 cursor-pointer my-auto">
                cancel
            </span>}
    </span>)
}

export { IconPill };