import axios from 'axios';
import { useEffect, useState } from 'react';

const usePermission = (permissionId) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchPermission = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/permissions/check-by-user/${permissionId}`);
            setHasPermission(response.data.hasPermission);
        } catch (err) {
            console.error('Error checking permission', err);
            setError(true);
            setHasPermission(false);
        } finally {
            setLoading(false);
        }
    };
    fetchPermission();
  }, [permissionId]);

  return { hasPermission, loading, error };
};

export default usePermission;
