import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from '../general/ConfirmModal';
import { ListsSelector } from '../general/ListsSelector';
import { listTypologiesByInternalId, uploadImageToS3 } from '../services/CoreService';
import { assingListsForVideo } from '../services/VitagListsService';
import { createVideo } from '../services/VitagVideosService';
import { Typologies } from '../utils/TypologiesEnum';
import { PERMISSIONS, useCheckPermission } from '../utils/permissions/PermissionsUtils';
import { UploaderInput } from './UploaderInput';
import { UploaderPreview } from './UploaderPreview';
import classNames from 'classnames';

function ModalNewVideo({showModal, setShowModal, externUrl}) {

    const initialVtgVideo = { title:'', url:'', miniature:'', sharingUrl:'', timeInSeconds:-1, timeInMiliSeconds:-1, isTrainingMaterial:false, projectionType: Typologies.PT_STANDARD };
    const [videoUrl, setVideoUrl] = useState('');
    const [vtgVideo, setVtgVideo] = useState(initialVtgVideo);
    const [videoDuration, setVideoDuration] = useState('');
    const [videoProvider, setVideoProvider] = useState();
    const [videoProviderProjection, setVideoProviderProjection] = useState(false);
    const [videoThumbnail, setVideoThumbnail] = useState('');
    const [allProjections, setProjections] = useState([]);
    const [selectedListIds, setSelectedListIds] = useState([]);
    const [videoReady, setVideoReady] = useState(false);
    const [showUploadedSuccess, setShowUploadedSuccess] = useState(false);
    const [workWithFile, setWorkFile] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const allowIsTrainingMaterial = useCheckPermission(PERMISSIONS.MARK_AS_TRAINING_MATERIAL);

    useEffect(() => {
        setVideoUrl(externUrl);
    }, [externUrl])

    useEffect(() => {
        if (showModal) {
          initModal();
        }
      }, [showModal]);

    const handlingTitleChange = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, title: inputValue});
    }

    const isInvalid = (value) => {
        return !value || value.trim() === '';
      };

    const handlingProviderChange = (event) => {
        setVideoProvider(event);
        if (event.internalId === Typologies.VT_YOUTUBE || 
            event.internalId === Typologies.VT_VIMEO || 
            event.internalId === Typologies.VT_TWITCH || 
            event.internalId === Typologies.VT_LIVESESSION ) 
        {
            setVideoProviderProjection(false);
        } else {
            setVideoProviderProjection(true);
        }
    }

    const handlingTrainingMaterialChange = (event) => {
        const inputChecked = event.target.checked;
        setVtgVideo({...vtgVideo, isTrainingMaterial: inputChecked});
    }

    const isUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const saveVideo = (evt) => {
        const btnTarget = evt.currentTarget;
        btnTarget.disabled = true;
        const thumbnailPromise = isUrl(videoThumbnail) ? 
            new Promise(response => response(videoThumbnail)) : 
            (   videoThumbnail ?
                uploadImageToS3(videoThumbnail).then(resp => resp.data.imageUrl) :
                new Promise(response => response(vtgVideo.miniature))
            );
        thumbnailPromise
            .then(thumbUrl => ({
                ...vtgVideo,
                url:videoUrl,
                miniature: thumbUrl
            }))
            .then(leVideo => createVideo(leVideo))
            .then(resp => {
                setVtgVideo({...vtgVideo, hashId:resp.data.hashId});
                setShowModal(false);
                setShowUploadedSuccess(true);
                if (selectedListIds.length > 0) {
                    return assingListsForVideo(resp.data.videoId, selectedListIds);
                }
                return new Promise(response => response('Nothing'));
            })
            .catch(err => console.error(err))
            .finally(() => btnTarget.disabled = false);
    }

    const initModal = () => {
        setVtgVideo(initialVtgVideo);
        setVideoDuration('');
        setVideoProvider(undefined);
        setVideoThumbnail('');
        setVideoUrl('');
        setVideoReady(false);
        setShowUploadedSuccess(false);
        listTypologiesByInternalId(Typologies.PROJECTION_TYPE)
            .then(resp => mapProjections(resp.data, true))
            .catch(err => console.error('Error getting projections', err));
    }

    const mapProjections = (data, reset = false) => {
        const mappedProjections = data.map(ic => ({
            value:ic.typologyId, label:ic.description
        }));
        const sortedProjections = mappedProjections.sort((a, b) => (a.value > b.value) ? 1 : -1)
        const newProjections = reset ? sortedProjections : [...sortedProjections, ...allProjections];
        setProjections(newProjections);
        setVtgVideo({...vtgVideo, projectionType: newProjections[0].value});
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const goToVideoPlayer = () => {
        if (videoUrl.startsWith('blob')) {
            history.push({
                pathname:`video/${vtgVideo.hashId}`,
                state:{localUrl:videoUrl, fileType:vtgVideo.fileType}
            });
        } else {
            history.push(`video/${vtgVideo.hashId}`);
        }
    }

    const handleProjectionType = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, projectionType: inputValue});
    }

    return (
        <>
        <Modal show={showModal} onHide={closeModal} size="xl">
            <Modal.Header>
                <button onClick={closeModal} 
                    title={t('modal_videomanagement_button_cancel')}
                    className="btn btn-sm">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>
                    {t('modal_videomanagement_title_newvideo')}
                </Modal.Title>
                <button onClick={saveVideo} 
                    title={t('modal_videomanagement_button_save')}
                    className="btn btn-sm text-vitag"
                    disabled={vtgVideo?.title?.trim() == '' || videoUrl?.trim() == ''}>
                    <span className="material-icons-round">save</span>
                </button>
            </Modal.Header>
            <Modal.Body className='px-5 pb-5'>
                <form className="form row" autoComplete='off'>
                    <div className="col-12 col-lg-6">

                        <UploaderInput
                            vtgVideo={vtgVideo}
                            setVtgVideo={setVtgVideo}
                            videoUrl={videoUrl}
                            setVideoUrl={setVideoUrl}
                            externUrl={externUrl}
                            setVideoReady={setVideoReady}
                            setVideoProvider={handlingProviderChange}
                            setVideoDuration={setVideoDuration}
                            setWorkFile={setWorkFile}
                            labelInCenter={false}/>

                        <label className="w-100 mt-3">
                            {t('modal_videomanagement_label_selectlistsforvideo')}
                        </label>
                        <ListsSelector
                            selectedListIds={selectedListIds}
                            setSelectedListIds={setSelectedListIds}
                            refreshList={showModal}
                            showMini={true}
                            listHeight={videoProviderProjection || workWithFile ?'300px':'190px'} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <UploaderPreview
                            vtgVideo={vtgVideo}
                            setVtgVideo={setVtgVideo}
                            videoReady={videoReady}
                            setVideoReady={setVideoReady}
                            videoDuration={videoDuration}
                            setVideoDuration={setVideoDuration}
                            videoThumbnail={videoThumbnail}
                            setVideoThumbnail={setVideoThumbnail}
                            videoUrl={videoUrl}
                            videoProvider={videoProvider}/>


                        <div className="w-100 form-group mt-4">
                            <label className='w-100' htmlFor="inputVideoTitle">
                                {t('modal_videomanagement_label_title')}
                            </label>
                            <input value={vtgVideo.title} onChange={handlingTitleChange}
                                className={classNames('form-control w-100', { 'invalid': isInvalid(vtgVideo.title) })}
                                name="inputVideoTitle" 
                                type="text"
                                placeholder={t('modal_videomanagement_placeholder_title')} 
                                required/>
                        </div>

                        { allowIsTrainingMaterial &&
                        <div className="w-100 d-flex justify-content-end mb-3">
                                <div className="form-check">
                                <input value={vtgVideo.isTrainingMaterial} onChange={handlingTrainingMaterialChange}
                                    className="form-check-input" type="checkbox" id="materialTraining"/>
                                <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                    {t('modal_videomanagement_label_istrainingmaterial')}
                                </label>
                            </div>
                        </div> }

                        { (videoProviderProjection || workWithFile) &&
                        <div className="w-100 mb-3">
                            <label htmlFor="inputProjectionOption">{t('modal_videomanagement_label_projection_type')}</label>
                            <select className="custom-select" id="inputProjectionOption" onChange={handleProjectionType}>
                                { allProjections.map(st => 
                                    <option key={st.value} value={st.value}>
                                        {t(st.label)}
                                    </option>
                                )}
                            </select>
                        </div>}



                    </div>
                </form>
            </Modal.Body>
        </Modal>
        <ConfirmModal
            showModal={showUploadedSuccess}
            setShowModal={setShowUploadedSuccess}
            showCancelButton={false}
            modalTitle={t('modal_videomanagement_submodal_success_title')}
            modalText={t('modal_videomanagement_submodal_success_text')}
            acceptCallback={goToVideoPlayer} />
        </>
    );
}

export { ModalNewVideo };
