import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { changeListName, createList, deleteList, listAllLists, listAllListsWithVideoAssign } from '../services/VitagListsService';
import { EmptyPanel } from './EmptyPanel';

function ListsSelector({
    selectedListIds, setSelectedListIds, listHeight = '180px',
    vtgVideoId, videoIds, allowEditLists = false, showMini = false
}) {

    const [availableLists, setAvailableLists] = useState([]);
    const [workingLists, setWorkingLists] = useState(false);
    const [newListName, setNewListName] = useState('');
    const [searchListText, setSearchListText] = useState('');
    const [savingNewList, setSavingNewList] = useState(false);
    const [showNewListInput, setShowNewListInput] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setAvailableLists([]);
        (vtgVideoId ? listAllListsWithVideoAssign(vtgVideoId) : listAllLists())
            .then(resp => {
                console.log('Getting lists: ', resp);
                const newLists = resp.data;
                setAvailableLists(newLists);
            })
            .catch(err => console.error('Error getting lists', err));
    }, [vtgVideoId, videoIds]);

    const triggerSelectList = (list) => {
        const newAvailableLists = availableLists
            .map(l => {
                if(l.listId == list.listId) {
                    l.selected = ! l.selected;
                }
                return l;
            });
        setAvailableLists(newAvailableLists);
        const selecteListIds = newAvailableLists
            .filter(list => list.selected)
            .map(list => list.listId);
        setSelectedListIds(selecteListIds);
    }

    const saveListChange = (list) => {
        setWorkingLists(true);
        changeListName(list.hashId, list.newListName)
            .then(_ => {
                const newList = {...list};
                newList.editing = false;
                newList.listName = list.newListName;
                const newAvailableLists = availableLists.map(l =>
                    list.listId == l.listId ? newList : l
                );
                setAvailableLists(newAvailableLists);
            })
            .catch(err => console.error('Impossible save changes...', err))
            .finally(() => setWorkingLists(false));
    }

    const startEditList = (list) => {
        const newAvailableLists = availableLists
            .map(l => {
                l.editing = l.listId == list.listId;
                if(l.editing) {
                    l.newListName = l.listName;
                }
                return l;
            });
        setAvailableLists(newAvailableLists);
    }

    const cancelEditing = () => {
        const newAvailableLists = availableLists
            .map(l => {
                l.editing = false;
                return l;
            });
        setAvailableLists(newAvailableLists);
    }

    const startDeleteList = (list) => {
        setWorkingLists(true);
        deleteList(list.hashId)
            .then(_ => {
                const newAvailableLists = availableLists.filter(l => list.listId != l.listId);
                setAvailableLists(newAvailableLists);
            })
            .catch(err => console.error('Impossible save changes...', err))
            .finally(() => setWorkingLists(false));
    }

    const handleChangeListName = (list, evt) => {
        const newAvailableLists = availableLists
            .map(l => {
                if(l.listId == list.listId) {
                    l.newListName = evt.target.value;
                }
                return l;
            });
        setAvailableLists(newAvailableLists);
    }

    const handleNewListName = evt => {
        setNewListName(evt.target.value);
    }

    const showNewListInputNow = () => {
        setShowNewListInput(true);
        setNewListName(searchListText);
    }

    const hideNewListInputNow = () => {
        setShowNewListInput(false);
        setNewListName('');
    }

    const createNewList = () => {
        setSavingNewList(true);
        createList(newListName)
            .then(resp => {
                const newList = resp.data;
                setShowNewListInput(false);
                setSavingNewList(false);
                setNewListName('');
                newList.selected = true;
                setAvailableLists([newList, ...availableLists]);
                setSelectedListIds([newList.listId, ...selectedListIds]);
            })
            .catch(err =>{
                console.error('Error creating list', err);
                setSavingNewList(false);
            });
    }

    return (<Row style={showMini ? {maxHeight: listHeight, overflowY:'scroll'} : {}}>
        <Col xs={12}>
            { ! showNewListInput &&
                <div className="d-flex justify-content-between gap-3 border-top border-bottom p-2">
                    <input value={searchListText} onChange={evt => setSearchListText(evt.target.value)}
                        className='form-control' type="text" placeholder={t('listsselector_placeholder_search')}/>
                    <button onClick={showNewListInputNow} title={t('listsselector_btn_newlist')}
                        type='button' className="btn btn-sm">
                        <span className="material-icons-round text-vitag m-0 p-0">
                            add
                        </span>
                    </button>
                </div> }
            { showNewListInput &&             
                <div className="d-flex py-2 px-3 hover align-items-start border-top border-bottom">
                    <Form.Group controlId='newListInput' className="my-auto flex-grow-1">
                        <Form.Control value={newListName} onChange={handleNewListName}
                            readOnly={savingNewList} disabled={savingNewList} autoFocus={true}
                            placeholder={t('listsselector_placeholder_new_listname')} type='text'/>
                    </Form.Group>
                    <button onClick={hideNewListInputNow} type='button' disabled={savingNewList}
                        className="btn btn-sm" title={t('listsselector_btn_cancel_titleattr')}>
                        <span className="material-icons-round text-muted">close</span>
                    </button>
                    <button onClick={createNewList} type='button' disabled={newListName.trim().length === 0 || savingNewList}
                        className="btn btn-sm" title={t('listsselector_btn_save_titleattr')}>
                            <span className="material-icons-round text-muted">save</span>
                    </button>
                </div> }
        </Col>
        { availableLists.length === 0 ?
        <Col xs={12}>
            <EmptyPanel message={t('listsselector_txt_without_lists_to_select')}/>
        </Col> : 
        <Col xs={12}>
            <div className="d-flex flex-column">
                { availableLists
                    .filter(list => list.listName.toLowerCase().includes(searchListText.toLowerCase()))
                    .map(list => 
                <div key={list.listId} className="d-flex py-2 px-3 hover align-items-start border-bottom">
                    <Form.Group controlId={'check_' + list.listId} key={list.listId} className="my-auto flex-grow-1">
                        <Form.Check type="checkbox">
                            { ! list.editing && <>
                            <Form.Check.Input type="checkbox" checked={list.selected}
                                onChange={() => triggerSelectList(list)}/>
                            <Form.Check.Label className='text-wrap cursor-pointer mx-2'>
                                {list.listName}
                            </Form.Check.Label> 
                            </> }
                            { list.editing &&
                                <input value={list.newListName} className='form-control form-control-sm' placeholder={t('listsselector_placeholder_edit_listname')} type='text'
                                    onChange={evt => handleChangeListName(list, evt)} /> }
                        </Form.Check>
                    </Form.Group>
                    { allowEditLists && ! list.editing && <>
                    <button onClick={() => startEditList(list)} type='button' className="btn btn-sm"
                        title={t('listsselector_btn_edit_titleattr')}>
                        <span className="material-icons-round text-muted">edit</span>
                    </button>
                    <button onClick={() => startDeleteList(list)} type='button' className="btn btn-sm"
                        title={t('listsselector_btn_delete_titleattr')}>
                        <span className="material-icons-round text-muted">delete</span>
                    </button> </> }
                    { allowEditLists && list.editing && <>
                    <button onClick={cancelEditing} type='button' className="btn btn-sm"
                        title={t('listsselector_btn_cancel_titleattr')}>
                        <span className="material-icons-round text-muted">close</span>
                    </button>
                    <button onClick={() => saveListChange(list)} type='button' className="btn btn-sm"
                        title={t('listsselector_btn_save_titleattr')}
                        disabled={! list.newListName?.trim().length || list.newListName === list.listName}>
                        <span className="material-icons-round text-muted">save</span>
                    </button> </> }
                </div> )}
            </div>
        </Col> }
    </Row>);

}

export { ListsSelector };
