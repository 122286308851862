import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { config } from '../config';
import { VitagFooter } from '../general/VitagFooter';
import { VitagTitleBar } from '../general/VitagTitleBar';
import { verificateAccount } from '../services/CoreService';

function VerificateAccount() {

    let { token } = useParams();
    const { t } = useTranslation();
    const[verifying, setVerifying] = useState(true);
    const[verified, setVerified] = useState(false);
    const[errorVerifying, setErrorVerifying] = useState(false);

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_verificate_account');
    }, []);

    useEffect(() => {
        setTimeout(_ => {
            verificateAccount(token)
            .then(_ => {
                console.log("Verified.");
                setVerifying(false);
                setVerified(true);
                setErrorVerifying(false);
            })
            .catch(err => {
                console.error('Error verifying account...', err);
                setVerifying(false);
                setVerified(false);
                setErrorVerifying(true);
            })
        }, 2400);
    }, []);

    return (
        <React.Fragment>
            <div className="container-fluid full-container bg-vitag pt-5">
                <div className="container w-75 bg-white extra-rounded px-5 py-4 d-flex flex-column">
                    <VitagTitleBar titleAtBar={t('verificateaccount_titleatbar')}></VitagTitleBar>
                    { verifying && <div className="d-flex flex-column py-2">
                        <div className="d-flex mb-3">
                            <div className="spinner-grow spinner-grow-sm my-auto" role="status">
                                <span className="sr-only">{t('verificateaccount_label_loading')}</span>
                            </div>
                            <h4 className='mx-2 my-0'>{t('verificateaccount_titleattr')}</h4>
                        </div>
                        <p className='d-block'>{t('verificateaccount_label_waiting')}</p>
                    </div> }
                    { verified && <div className="d-flex flex-column py-2">
                        <div className="d-flex mb-3">
                            <span className="material-icons-round text-success my-auto">check</span>
                            <h4 className='mx-2 my-0'>{t('verificateaccount_msg_success')}</h4>
                        </div>
                        <p>{t('verificateaccount_msg_loginnow')}</p>
                        <div className="d-flex justify-content-end border-top pt-3">
                            <Link to="/login" className='btn btn-primary bg-vitag'>{t('verificateaccount_msg_login')}</Link>
                        </div>
                    </div> }
                    { errorVerifying && <div className="d-flex flex-column py-2">
                        <div className="d-flex mb-3">
                            <span className="material-icons-round text-danger my-auto">cancel</span>
                            <h4 className='mx-2 my-0'>{t('verificateaccount_msg_error')}</h4>
                        </div>
                        <p>{t('verificateaccount_msg_errorbody')}</p>
                    </div> }
                </div>
            </div>
        <VitagFooter></VitagFooter>
        </React.Fragment>
    )
}

export { VerificateAccount };