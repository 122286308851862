import axios from 'axios';

function subscriptionsByOrganization(organizationId) {
    return axios.get(`/vtg-subscriptions/by-organization/${organizationId}`, {params:{onlyMostRecent:true}});
}

function getSubsInfo() {
    return axios.get('/user-account/my-subscriptions');
}

function createSubscription(isUpgrade=false, source="DEFAULT", ownerId) {
    const params = new URLSearchParams();
    params.append('isUpgrade', isUpgrade);
    params.append('source', source);
    params.append('outer', false);
    params.append('owner', ownerId)
    return axios.post(`/user-account/new-subscription?${params.toString()}`);
}

export {
    subscriptionsByOrganization, getSubsInfo, createSubscription
};