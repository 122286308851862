import React, { useEffect, useState  } from 'react';
import classNames from 'classnames';
import { convertMillisecondsToTime, convertTextToMilliseconds } from '../NabUtils';
import { getPreferenceAsNumber, PREFERENCES, savePreference } from "../preferences/user-preferences";
import { useTranslation } from "react-i18next";
import { useCustomToast } from '../toasts/useCustomToast';

function OffsetSelector () {
  const { t } = useTranslation();
  const { addToast } = useCustomToast();

  const [startOffsetAsText, setStartOffsetAsText] = useState('00:02:000');
  const [endOffsetAsText, setEndOffsetAsText] = useState('00:02:000');
  const [currentStartOffset, setCurrentStartOffset] = useState(getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE));
  const [currentEndOffset, setCurrentEndOffset] = useState(getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER));
  const [invalidStartInterval, setInvalidStartInterval] = useState(false);
  const [invalidEndInterval, setInvalidEndInterval] = useState(false);

  useEffect(() => {
    const convertedStartTime  = convertMillisecondsToTime(currentStartOffset);
    const convertedEndTime  = convertMillisecondsToTime(currentEndOffset);

    setStartOffsetAsText(convertedStartTime);
    setEndOffsetAsText(convertedEndTime);
  }, []);

  const handleInputChange  = (evt, isStart) => {
    const newValue = evt.target.value;
    const convertedTime  = convertTextToMilliseconds(newValue);


    if (isStart) {
        setStartOffsetAsText(newValue);
        if (convertedTime !== false) {
            setCurrentStartOffset(convertedTime);
            setInvalidStartInterval(false);
        } else {
            setInvalidStartInterval(true);    
        }
    } else {
        setEndOffsetAsText(newValue);
        if (convertedTime !== false ) {
            setCurrentEndOffset(convertedTime);
            setInvalidEndInterval(false);
        } else {
            setInvalidEndInterval(true);
        }
    }
  };

  const changeInterval = (target, backward) => {
    const startOt = convertTextToMilliseconds(startOffsetAsText);
    const endOt = convertTextToMilliseconds(endOffsetAsText);
    let newValueStart = startOt;
    let newValueEnd = endOt;

    if (startOt !== false && endOt !== false) {
        const adjustment = backward ? -500 : 500;           
        if (target === 'start') {
            newValueStart = Math.max(startOt + adjustment, 0);

            setStartOffsetAsText(convertMillisecondsToTime(newValueStart));
            setCurrentStartOffset(newValueStart);
            setInvalidStartInterval(false);
        } else {
            newValueEnd = Math.max(endOt + adjustment, 0);

            setEndOffsetAsText(convertMillisecondsToTime(newValueEnd));
            setCurrentEndOffset(newValueEnd);
            setInvalidEndInterval(false);
        }
    }
  };

  const handleSaveOffsets = () => {
    const startOt = convertTextToMilliseconds(startOffsetAsText);
    const endOt = convertTextToMilliseconds(endOffsetAsText);

    savePreference(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE, startOt);
    savePreference(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER, endOt);

    addToast({
        header: t('page_profile_section_config_changeOffsets_toast_title'),
        body: t('page_profile_section_config_changeOffsets_toast_body'),
        variant: "success"
    });

  };

  const validateMark = () => {
    if (invalidStartInterval || invalidEndInterval
    ) return true;
  }

  return (
  <div className='d-flex gap-0'>
    <div className="d-flex row mt-2 gap-0">
      <span className="d-flex col-12 col-lg-auto align-items-center pr-0 mt-lg-0">{t('page_profile_section_config_changeOffsets_label_before') + ':'}</span>
      
      <div className="d-flex col-12 col-lg-3 flex-column align-items-center mt-lg-0">
          <div className="input-group">
              <div className="input-group-prepend gap-0 mx-0">
                  <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
              </div>
              <input type="text" className={classNames('form-control', { 'invalid': invalidStartInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                      onChange={(e) => handleInputChange(e, true)}
                      value={startOffsetAsText} 
                      id="inputStartTime" placeholder='mm:ss:aa' />
              <div className="input-group-append">
                  <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
              </div>
          </div>
      </div>

      <span className="d-flex col-12 col-lg-auto align-items-center pl-3 pr-0 mt-2 mt-lg-0">{t('page_profile_section_config_changeOffsets_label_after') + ':'}</span>

      <div className="d-flex col-12 col-lg-3 flex-column align-items-center mr-auto mt-lg-0">
          <div className="input-group">
              <div className="input-group-prepend gap-0 mx-0">
                  <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
              </div>
              <input type="text" className={classNames('form-control', { 'invalid': invalidEndInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                      onChange={(e) => handleInputChange(e, false)}
                      value={endOffsetAsText} 
                      id="inputEndTime" placeholder='mm:ss:aa' />
              <div className="input-group-append">
                  <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
              </div>
          </div>
      </div>

      <div className="d-flex col-12 col-lg-auto flex-column align-items-center mt-3 mt-lg-0">
        <button onClick={() => handleSaveOffsets()} disabled={validateMark()}
            className="btn btn-outline-dark col-12">
            {t('page_profile_section_save_changeOffsets')}
        </button>
      </div>
    </div>
  </div>
  );
}

export { OffsetSelector };