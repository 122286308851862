import { useEffect, useState } from "react"
import { createManualToken, listMyManualTokens } from "../services/CoreService";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { fixDate } from '../utils/NabUtils';

function TokenManagement() {

    const [myTokens, setMyTokens] = useState([]);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        listMyManualTokens()
            .then(resp => setMyTokens(resp.data))
            .catch(err => console.error('Error getting tokens', err));
    }, []);

    const createNewManualToken = () => {
        createManualToken()
            .then(resp => setMyTokens([...myTokens, resp.data]))
            .catch(err => console.error('Error creating manual token', err));
    }

    const copyTokenToClipboard = (token) => {
        console.log('Copied!!!');
        navigator.clipboard.writeText(token);
    }

    const copiedPopover = <Popover>
        <Popover.Content>
            Copiado
        </Popover.Content>
    </Popover>

    return <>
        <h4 className="mb-3">Tus tokens</h4>
        <div className="d-flex flex-column mb-3">
            { myTokens.length == 0 && <span className="text-muted extra-rounded border p-3">
                No tienes tokens disponibles, puedes usar el botón 'Crear nuevo token' para crear tu primer token.
            </span> }

            { myTokens.map((tkn, i) => {
                const copiedBtn = <OverlayTrigger trigger='click' placement="top" delay={5000} overlay={copiedPopover}>
                    <button onClick={() => copyTokenToClipboard(tkn.token) && copiedBtn.hide()}
                        className="btn btn-outline-secondary d-flex align-items-center">
                        <span className="line-0 material-icons-round">copy</span>
                    </button>
                </OverlayTrigger>
                return <div key={tkn.tokenCredentialId} className={(i>0?'border-top':'') + " d-flex flex-column py-3 px-2"}>
                    <div className="input-group mb-2">
                        <input value={tkn.token} type="text" readOnly className="form-control text-truncate"/>
                        <div className="input-group-append">
                            { copiedBtn }
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="small">Válido desde: <b>{ fixDate(tkn.effectiveDate, timezone) }</b></span>
                        <span className="small">Válido hasta: <b>{ fixDate(tkn.expiryDate, timezone) }</b></span>
                    </div>
                </div>
            })}
        </div>
        <div className="d-flex">
            <button onClick={createNewManualToken} className="btn btn-sm btn-secondary ml-auto">
                Crear nuevo token
            </button>
        </div>
    </>
}

export { TokenManagement }
