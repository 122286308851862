import { useEffect, useState } from "react";
import { getPermissionsByUser } from "../../services/CoreService";

function useCheckPermission(permissionInternalId) {

    const[permissionPresent, setPermissionPresent] = useState(false);
    const[userPermissions, setUserPermissions] = useState(null);

    useEffect(() => {
        console.log('Checking permission... ' + permissionInternalId);
        const checkPermission = (permissions, internalId) => {
            if (permissions) {
                setPermissionPresent(
                    permissions.find(perm => perm.internalId === internalId) !== undefined
                );
            }
        };
        if (userPermissions) {
            checkPermission(userPermissions, permissionInternalId);
            return;
        }
        getPermissionsByUser()
            .then(ps => {
                const currentPermissions = ps.data;
                setUserPermissions(currentPermissions);
                checkPermission(currentPermissions, permissionInternalId);
            })
            .catch(err => console.error('Error getting permissions', err));
    }, []);

    return permissionPresent;
}

const PERMISSIONS = {
    MARK_AS_TRAINING_MATERIAL: 111,
    ACCESS_DEVELOPER_SECTION: 112
}

export { useCheckPermission, PERMISSIONS };