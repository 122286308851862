import React, { useState, useEffect } from 'react';
import { Alert, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from '../config';
import { VitagFooter } from '../general/VitagFooter';
import { VitagTitleBar } from '../general/VitagTitleBar';
import { startRecoveryPassword } from '../services/CoreService';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function RequestPasswordRecovery() {

    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [userEmail, setUserEmail] = useState('');
    const [showPasswordRecoveryMessage, setShowPasswordRecoveryMessage] = useState(false);

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_request_password_recovery');
    }, []);

    const handleUserInputChange = (event) => {
        const value = event.target.value;
        setUserEmail(value);
    }

    const handleEnter = (event) => {
        const leKey = event.which || event.charCode || event.keyCode;
        if (leKey === 13 || leKey === '13') {
            tryRecoveryPassword();
            event.preventDefault();
        }
    }

    const tryRecoveryPassword = () => {
        startRecoveryPassword(userEmail)
            .then(_ => {
                console.log('Password recovery start');
                setShowPasswordRecoveryMessage(true);
            })
            .catch(err => {
                console.error('Impossible recovery password', err);
                addToast({
                    header: t('page_passwordrecovery_toast_errorinvalidaccount_title'),
                    title: t('page_passwordrecovery_toast_errorinvalidaccount_text'),
                    variant: 'error'
                });
                setShowPasswordRecoveryMessage(false);
            });
    }
    
    return(
        <div className="container-fluid full-container bg-vitag px-4 px-md-5">
            <div className="row justify-content-center">
                <div className="w-100 d-block d-sm-none" style={{paddingTop:'1em'}}></div>
                <div className="w-100 d-none d-sm-block" style={{paddingTop:'6em'}}></div>
                <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-5 px-4 py-3 mt-3 bg-white border extra-rounded" style={{maxWidth:'800px !important'}}>
                    <VitagTitleBar titleAtBar={t('page_passwordrecovery_title')}/>
                    { ! showPasswordRecoveryMessage &&
                    <React.Fragment>
                        <Col sm={12}>
                            <p>{t('page_passwordrecovery_text')}</p>
                        </Col>
                        <Col sm={12} className="form-group">
                            <label htmlFor='userEmail'>
                                {t('page_passwordrecovery_label_username')}
                            </label>
                            <input
                                id="userEmail" name="userEmail" type="email" className="form-control"
                                value={userEmail} onChange={handleUserInputChange} onKeyPress={handleEnter}
                                placeholder={t('page_passwordrecovery_placeholder_username')} required/>
                        </Col>
                        <Col sm={12} className="mt-4">
                            <button onClick={tryRecoveryPassword} disabled={! userEmail}
                                className="btn btn-block btn-primary bg-vitag mb-4">
                                {t('page_passwordrecovery_btn_recoverpassword')}
                            </button>
                            <p className='text-center text-muted border-top pt-3'>
                                {t('page_passwordrecovery_message_orelse')}
                            </p>
                            <div className="d-flex justify-content-between">
                                <Link to="/login" className="btn btn-link vitag-text">
                                    {t('page_passwordrecovery_btn_gotosignin')}
                                </Link>
                                <Link to="/sign-up" className="btn btn-link vitag-text">
                                    {t('page_passwordrecovery_btn_gotosignup')}
                                </Link>
                            </div>
                        </Col>
                    </React.Fragment> }
                    { showPasswordRecoveryMessage &&
                    <Alert variant='success'>
                        <Alert.Heading>{t('page_passwordrecovery_alert_success_title')}</Alert.Heading>
                        <p className="m-0">{t('page_passwordrecovery_alert_success_text')}</p>
                        <hr/>
                        <Link to="/login" className="btn btn-outline-success">
                            {t('page_passwordrecovery_alert_success_btn_gotosignin')}
                        </Link>
                    </Alert> }
                </div>
            </div>

            <VitagFooter/>

        </div>
    );

}

export { RequestPasswordRecovery };