import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { convertSecondsToTime } from "../../utils/NabUtils";
import './VimeoPlayer.css';
import { useLocation } from "react-router-dom";

function VimeoPlayer({
        vimeoVideoId,
        videoPlaying, setVideoPlaying,
        videoDuration, setVideoDuration,
        currentVideoTime, setCurrentVideoTime,
        externSeekTo, externSetVimeoPlayer
    }) {

    const [vimeoPlayer, setVimeoPlayer] = useState();
    const [vimeoIsPlaying, setVimeoIsPlaying] = useState(false);
    const [vimeoDurationText, setVimeoDurationText] = useState('-:--');
    const [vimeoCurrentTimeText, setVimeoCurrentTimeText] = useState('-:--');
    const [vimeoProgressPercent, setVimeoProgressPercent] = useState(0);
    const [vimeoVolume, setVimeoVolume] = useState(100);
    const [vimeoTimeIndicator, setVimeoTimeIndicator] = useState('-:--');
    const [vimeoShowTimeIndicator, setVimeoShowTimeIndicator] = useState(false);
    const [vimeoPositionTimeIndicator, setVimeoPositionTimeIndicator] = useState(0);
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            var iframe = document.getElementById('vimeo-player');
            var player = new window.Vimeo.Player(iframe);
            player.on('timeupdate', data => {
                setVimeoProgressPercent(data.percent * 100);
                changeVideoCurrentTime(data.seconds);
                changeVideoDuration(data.duration);
            });
            player.on('pause', _ => {
                setVimeoIsPlaying(false);
                setVideoPlaying(false);
            });
            player.on('play', _ => {
                setVimeoIsPlaying(true);
                setVideoPlaying(true);
            });
            player.ready().then(() => {
                console.log('Vimeo player is ready');
                player.pause();
                player.getDuration().then(duration => {
                    changeVideoDuration(duration);
                    changeVideoCurrentTime(0.0);
                });
                const newTime = location.state?.last || 0;
                player.setCurrentTime(newTime);
                setCurrentVideoTime(newTime);
            });
            setVimeoPlayer(player);
            externSetVimeoPlayer(player);
        }, 800);
    }, []);

    useEffect(() => {
        videoPlaying ? vimeoPlay() : vimeoPause();
    }, [videoPlaying]);

    useEffect(() => {
        console.log('Extern seek-to: ' + externSeekTo);
        changeVideoCurrentTime(externSeekTo);
        vimeoSeekTo(externSeekTo);
    }, [externSeekTo]);

    const changeVideoDuration = (timeInSeconds) => {
        const duration = convertSecondsToTime(timeInSeconds);
        setVideoDuration(timeInSeconds);
        setVimeoDurationText(duration);
    }

    const changeVideoCurrentTime = (timeInSeconds) => {
        const currentTime = convertSecondsToTime(timeInSeconds);
        setCurrentVideoTime(timeInSeconds);
        setVimeoCurrentTimeText(currentTime);
    }

    const handleMoveToClick = evt => {
        const progressBarContainer = document.getElementById('vimeo-progress-bar').getBoundingClientRect();
        const position = evt.clientX - progressBarContainer.left;
        const moveToTime = videoDuration * (position / progressBarContainer.width);
        vimeoSeekTo(moveToTime);
    }

    const handleProgressBarInside = evt => {
        setVimeoShowTimeIndicator(true);
    }

    const handleProgressBarOutside = evt => {
        setVimeoShowTimeIndicator(false);
    }

    const handleProgressBarMovement = evt => {
        const pbRect = document.getElementById('vimeo-progress-bar').getBoundingClientRect();
        const time = convertSecondsToTime(videoDuration * (evt.clientX - pbRect.left) / pbRect.width);
        setVimeoTimeIndicator(time);
        setVimeoPositionTimeIndicator(evt.clientX - 32);
    }

    const handleVolumeChange = evt => {
        const newVolume = evt.target.value;
        setVimeoVolume(newVolume);
        vimeoPlayer?.setVolume(newVolume / 100).then(_ => console.log('Volume updated...'));
    }

    const vimeoPlay = () => {
        vimeoPlayer?.play();
    }

    const vimeoPause = () => {
        vimeoPlayer?.pause();
    }

    const vimeoSeekTo = newTime => {
        setCurrentVideoTime(newTime);
        vimeoPlayer?.setCurrentTime(newTime);
    }

    return (
        <>
            <iframe
                id='vimeo-player' frameBorder="0" crossOrigin="anonymous"
                style={{position:'absolute', width:'100%', height:'100%'}}
                src={`https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&muted=1&loop=0&background=1`}>
            </iframe>
            <div className="d-flex w-100 position-absolute p-3 gap-3"
                style={{bottom:0,backgroundColor:'rgba(57,57,57,0.12)'}}>
                { ! vimeoIsPlaying ?
                <button onClick={vimeoPlay} className="btn text-light">
                    <FontAwesomeIcon icon={faPlay}/>
                </button> :
                <button onClick={vimeoPause} className="btn text-light">
                    <FontAwesomeIcon icon={faPause}/>
                </button> }
                <span className="my-auto small text-light" style={{minWidth:'80px'}}>
                    { vimeoCurrentTimeText + ' / ' + vimeoDurationText }
                </span>
                { vimeoShowTimeIndicator &&
                <span className="time-indicator position-absolute" style={{left:vimeoPositionTimeIndicator+'px'}}>
                    {vimeoTimeIndicator}
                </span>}
                <div id="vimeo-progress-bar" className="my-auto vimeo-progress-bar" style={{flexGrow:'1'}}
                    onClick={handleMoveToClick} onMouseMove={handleProgressBarMovement}
                    onMouseEnter={handleProgressBarInside} onMouseLeave={handleProgressBarOutside}>
                    <div className="le-progress position-absolute" style={{top:0,bottom:0,width:vimeoProgressPercent+'%'}}></div>
                </div>
                <div className="dropdown">
                    <button className="btn text-light" data-toggle="dropdown">
                        <FontAwesomeIcon icon={faVolumeUp}/>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-0 m-0" style={{backgroundColor:'#edededaa',lineHeight:'0'}}>
                        <input id="volume-control" className="volume-control m-0 px-3 py-1 rounded" type="range" style={{backgroundColor:'#ededed33'}}
                            onChange={handleVolumeChange} min={0} max={100} step={2} value={vimeoVolume}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export { VimeoPlayer }