import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FavItem } from '../general/FavItem';
import { addFavoriteTagsAtVideo } from '../services/VitagFavoritesService';
import { ModalButtonPad } from './ModalButtonPad';
import { ModalReOrderFavs } from './ModalReOrderFavs';

function ModalManageFavs({ videoId, currentFavs, availableTags, setFavorites, showModal, setShowModal, savedTagListener }) {

    const[leTags, setLeTags] = useState([]);
    const[overrideGlobalFavs, setOverrideGlobalFavs] = useState(false);
    const[finalTags, setFinalTags] = useState([]);
    const[refresh, setRefresh] = useState();
    const[filter, setFilter] = useState('');
    const[showButtonPadModal, setShowButtonPadModal] = useState(false);
    const[showReOrderFavModal, setShowReOrderFavModal] = useState(false);
    const { t } = useTranslation();

    const initModal = () => {
        const currentFavIds = currentFavs?.map(t => t.tagId) || [];
        const tags = [...availableTags];
        tags.sort((t1, t2) => (t2.isFav * 1000 + t2.subTags.length) - (t1.isFav * 1000 + t1.subTags.length));
        tags?.forEach(t => {
            t.selected = false;
            t.position = 100;
            t.subTags = t.subTags.sort((t1, t2) => t1.sortPosition - t2.sortPosition);
            t.subTags.forEach(st => {st.selected = false; st.position = 100;});
        });
        tags?.forEach(t => {
            const fav = currentFavs.find(fav => fav.tagId === t.tagId);
            if (fav) {
                t.selected = true;
                t.position = fav.position;
            }
            t.subTags.forEach(st => {
                const subFav = currentFavs.find(subFav => subFav.tagId === st.tagId);
                if (subFav) {
                    st.selected = true;
                    st.position = subFav.position;
                }
            })
        });
        setOverrideGlobalFavs(false);
        setFinalTags(tags);
        setLeTags(tags);
        setFilter('');
    }

    useEffect(() => {
        if (refresh) {
            const newFinalTags = [...finalTags];
            newFinalTags[refresh[0]] = refresh[1];
            setFinalTags(newFinalTags);
        }
    }, [refresh])

    const addNewTag = (newTag) => {
        newTag.selected = false;
        setLeTags([...leTags, newTag]);
    }

    const saveFavs = () => {
        const newFavs = [];
        finalTags.forEach(t => {
            if (t.selected) {
                newFavs.push(t);
            }
            t.subTags.forEach(st => {
                if (st.selected) {
                    newFavs.push(st);
                }
            });
        });

        newFavs.sort((a, b) => a.position - b.position);
        
        const successCb = () => {
            setFavorites && setFavorites(newFavs);
            closeThisModal();
        }
        addFavoriteTagsAtVideo(
            videoId, newFavs.map(fav => ({videoId, tagId: fav.tagId, position:fav.position})), overrideGlobalFavs
        )
        .then(resp => {
            console.log('Favs updated successful', resp.data);
            successCb();
        })
        .catch(err => console.error('Impossible save favs', err))
    }

    const handleCheckbox = (evt) => {
        const value = evt.target.checked;
        setOverrideGlobalFavs(value);
    }

    const openButtonPadModal = () => {
        setShowButtonPadModal(true);
    }

    const openReOrderFavModal = () => {
        setShowReOrderFavModal(true);
    }

    const closeThisModal = () => {
        savedTagListener && savedTagListener();
        setShowModal(false);
    };

    const matchTag = (tag) => {
        const searchFilter = filter.toLocaleLowerCase();
        if(tag.name.toLowerCase().includes(searchFilter)) {
            return true;
        }
        for(let st of tag.subTags) {
            if(st.name.toLowerCase().includes(searchFilter)) {
                return true
            }
        }
        return false;
    }

    return ( <>
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg'>
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_cancel')}
                className="btn btn-sm text-muted">
                <span className='material-icons-round'>close</span>
            </button>
            <Modal.Title>{t('modal_managefavs_title')}</Modal.Title>
            <div className="d-flex gap-2">
                <div className="dropdown">
                    <button title={t('modal_managefavs_btn_options_titleattr')}
                        className='btn btn-sm align-self-center dropdown-toggle' data-toggle="dropdown">
                        <span className="material-icons-round">settings</span>
                    </button>
                    <div className="dropdown-menu">
                        <div className="dropdown-item cursor-pointer" onClick={evt => evt.stopPropagation()}>
                            <div className="form-check">
                                <input checked={overrideGlobalFavs} onChange={handleCheckbox}
                                    className="form-check-input" type="checkbox" id="materialTraining"/>
                                <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                    {t('modal_managefavs_option_memorizenewfavs')}
                                </label>
                            </div>
                        </div>
                        <div className="dropdown-item cursor-pointer" onClick={openButtonPadModal}>
                            {t('modal_managefavs_option_createtag')}
                        </div>
                        <div className="dropdown-item cursor-pointer" onClick={openReOrderFavModal}>
                            {t('modal_managefavs_option_ordertag')}
                        </div>
                    </div>
                </div>
                <button onClick={saveFavs}
                    title={t('modal_managefavs_btn_savefavs_titleattr')}
                    className="btn btn-sm text-vitag">
                    <span className='material-icons-round'>save</span>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body className='px-5'>
            <div className="py-2 d-flex align-items-center">
                <p className='m-0 w-100 w-md-50'>{t('modal_managefavs_text')}</p>
                <input type="text" className="form-control" placeholder={t('modal_managefavs_search_placeholder')}
                    value={filter} onChange={evt => setFilter(evt.target.value)}/>
            </div>
            <div className="accordion" id="accordionFavs"
                style={{height:'360px',maxHeight:'360px', overflow:'scroll'}}>
                { leTags?.filter(t => matchTag(t))?.map((t, i) =>
                    <FavItem
                        key={t.tagId}
                        currentTag={t}
                        position={i}
                        parentId='accordionFavs'
                        filter={filter}
                        setRefresh={setRefresh}/> )}
            </div>
        </Modal.Body>
    </Modal>
    <ModalButtonPad
        showModal={showButtonPadModal}
        setShowModal={setShowButtonPadModal}
        savedTagListener={addNewTag}/>
    <ModalReOrderFavs
        videoId={videoId}
        currentFavs={finalTags}
        updateFavs={setFinalTags}
        showModal={showReOrderFavModal}
        setShowModal={setShowReOrderFavModal}/>
    </>);
}

export { ModalManageFavs }
