import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { ConfirmModal } from "../general/ConfirmModal";
import { EmptyPanel } from "../general/EmptyPanel";
import { LoadingPanel } from "../general/LoadingPanel";
import { PaginationControl } from "../general/PaginationControl";
import { SimpleContextSelector } from "../general/SimpleContextSelector";
import { TagItem } from "../general/TagItem";
import { ModalButtonPad } from "../modals/ModalButtonPad";
import { ModalShare } from "../modals/ModalShare";
import { ModalSharedList } from "../modals/ModalSharedList";
import { getLoggedUser } from "../services/AuthorizarionService";
import { cloneTag, ignoreTag, listAllTags, listForExport, softDeleteTag, tagUsage } from "../services/VitagTagsService";
import { HomeBase } from "./HomeBase";
import { IconPill } from "../general/IconPill";
import { getPreferenceAsBool, getPreferenceAsNumber, PREFERENCES, savePreference } from "../utils/preferences/user-preferences";
import { GeneralContext } from "../contexts/general-context";
import { useHistory } from "react-router-dom";
import { config } from "../config";
import { ModalImportTags } from "../modals/ModalImportTags";
import { faSortAlphaDown, faSortAlphaUp, faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TagsHome() {

    const [myTags, setMyTags] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(
        getPreferenceAsNumber(PREFERENCES.PAGE_SIZE)
    );
    const [currentTag, setCurrentTag] = useState();
    const [loggedUser, setLoggedUser] = useState();
    const [showShareModal, setShowShareModal] = useState(false);
    const [showSharedListModal, setShowSharedListModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmCloneModal, setShowConfirmCloneModal] = useState(false);
    const [showTagDetailModal, setShowTagDetailModal] = useState(false);
    const [showModalButtonPad, setShowModalButtonPad] = useState();
    const [showModalImportTags, setShowModalImportTags] = useState(false);
    const [tagUpdatedListener, setTagUpdatedListener] = useState();
    const [cloneTagRequest, setCloneTagRequest] = useState({ newName:'', newOwnerId:undefined });
    const [cloneInOtherContext, setCloneInOtherContext] = useState(false);
    const [viewAsGrid, setViewAsGrid] = useState(
        getPreferenceAsBool(PREFERENCES.VIEW_AS_GRID)
    );
    const [loading, setLoading] = useState(true);
    const [extraButtons, setExtraButtons] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { t } = useTranslation();
    const { currentContext } = useContext(GeneralContext);
    const history = useHistory();
    const lastTimeoutId = useRef(-1);
    const [tagStats, setTagStats] = useState(new Map());
    const [ sortCriterias ] = useState([
        {value:'date-desc', name:'date', asc:false, icon:faSortAmountUp, label:'criteria_name_videos_date_desc'},
        {value:'name-asc', name:'name', asc:true, icon:faSortAlphaUp, label:'criteria_name_videos_name_asc'},
        {value:'date-asc', name:'date', asc:true, icon:faSortAmountDown, label:'criteria_name_videos_date_asc'},
        {value:'name-desc', name:'name', asc:false, icon:faSortAlphaDown, label:'criteria_name_videos_name_desc'},
    ]);
    const [selectedSortCriteria, setSelectedSortCriteria] = useState(sortCriterias[0]);

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_tags_home');
        setLoggedUser(getLoggedUser());
    }, []);

    useEffect(() => {
        getAllTags(searchText);
    }, [currentPage, pageSize])

    const resetSearch = () => {
        setSearchText('');
        setCurrentPage(0);
        getAllTags();
    }

    const getAllTags = (search, selectedSortCriteria) => {
        if(! selectedSortCriteria) {
            selectedSortCriteria = sortCriterias[0];
        }
        listAllTags(search, currentPage, pageSize, false, true, selectedSortCriteria)
            .then(resp => {
                const total = resp.headers['x-count-total'];
                setTotalCount(total);
                const sortedTags = resp.data.map(tag => ({
                    ...tag,
                    subTags: tag.subTags.slice().sort((a, b) => a.tagId - b.tagId)
                  }));
                setMyTags(sortedTags);
                setTimeout(setLoading, 200, false);
            })
            .catch(err => {
                console.error('Error getting my tags...', err);
                setLoading(false);
            });
    }

    const deleteTag = () => {
        const deletingTag = {...currentTag};
        softDeleteTag(deletingTag.tagId)
            .then(resp => {
                console.log('Deleted ;)', resp);
                const newTags = myTags.filter(t => t.tagId !== deletingTag.tagId);
                setMyTags(newTags);
            })
            .catch(err => console.error('Error deleting tag :(', err));
    }

    const makeCloneTag = () => {
        let newTagName = cloneTagRequest.newName;
        if (! newTagName || newTagName.trim().length === 0) {
            newTagName = `${currentTag.name} ${t('page_videos_newtitlecopy')}`;
        }
        cloneTag(currentTag.tagId, {...cloneTagRequest, newName: newTagName})
            .then(resp => {
                console.log('Cloned ;)', resp.data);
                setTagUpdatedListener(resp.data);
                setShowSuccessModal(true);
            })
            .catch(err => console.error('Error cloning tag :(', err));
    }

    const exportTags = () => {
        listForExport(searchText)
            .then(resp => {
                console.log('Export this...', resp);
                let fileName = t('general_tagscsvfilename');
                fileName = `${fileName}-${currentContext?.name || ''}.csv`
                var blob = new Blob([resp.data], {type:'text/csv;charset=utf-8'});
                FileSaver.saveAs(blob, fileName);
            })
            .catch(err => console.error('Error getting tags for export', err));
    }

    const startImportTags = () => {
        setShowModalImportTags(true);
    }

    const startEditTag = (tag) => {
        setCurrentTag(tag);
        setShowModalButtonPad(true);
    }

    const startShareTag = (tag) => {
        setCurrentTag(tag);
        setShowShareModal(true);
    }

    const startSharedListTag = (tag) => {
        setCurrentTag(tag);
        setShowSharedListModal(true);
    }

    const confirmDeleteTag = (tag) => {
        setCurrentTag(tag);
        setShowConfirmDeleteModal(true);
    }

    const dismissTag = (tag) => {
        ignoreTag(tag.tagId)
            .then(_ => {
                console.log('Ignored...');
                setTagUpdatedListener(new Date());
            })
            .catch(err => console.error('Error ignoring tag :(', err));
    }

    const confirmCloneTag = (tag) => {
        setCurrentTag(tag);
        setCloneTagRequest({newName: `${tag.name} ${t('page_videos_newtitlecopy')}`, newOwnerId: undefined});
        setCloneInOtherContext(false);
        setShowConfirmCloneModal(true);
    }

    const startForNewTag = () => {
        setCurrentTag(null);
        setShowModalButtonPad(true);
    }

    const viewTagDetail = (tag) => {
        setCurrentTag(tag);
        setShowTagDetailModal(true);
        tagUsage(tag.tagId)
            .then(resp => {
                console.log('Usage for tag: ' + tag.name, resp.data);
                const newTagStats = new Map();
                resp.data.forEach(ts => newTagStats.set(ts.entityId, ts.value));
                setTagStats(newTagStats);
            })
            .catch(err => console.error('Error getting tag stats', err));
    }

    const checkContextHandler = evt => {
        if (! evt.target.checked) {
            setCloneTagRequest({...cloneTagRequest, newOwnerId: undefined});
        }
        setCloneInOtherContext(evt.target.checked);
    }

    const changeTagNameHandler = evt => {
        setCloneTagRequest({...cloneTagRequest, newName: evt.target.value});
    }

    const changeSelectedContext = evt => {
        setCloneTagRequest({...cloneTagRequest, newOwnerId: evt.organizationId});
    }

    const triggerGridListView = () => {
        savePreference(PREFERENCES.VIEW_AS_GRID, ! viewAsGrid);
        setViewAsGrid(! viewAsGrid);
    }

    const handleTagClick = (tag) => {
        console.log('Click at:', tag);
        history.push({ pathname:'/videos', state:{ tag } });
    }

    const changeSortCriteria = (newSortCriteria) => {
        setSelectedSortCriteria(newSortCriteria);
        getAllTags(searchText, newSortCriteria);
    }

    const handleSearch = (evt) => {
        const newSearch = evt.target.value;
        setSearchText(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(() => {
            getAllTags(newSearch);
        }, 800);
    }

    const handleShareByLink = (shareByLink) => {
        getAllTags();
    }


    return (
        <React.Fragment>
        <HomeBase
            showSearchBar={false}
            showExtraButtons={false}
            resetListener={resetSearch}
            currentTabName="tags">
            <div className="row">
                <div className='mb-2 col-12 col-sm-12 col-md-6 col-lg-5 d-flex flex-column'>
                    <div className="w-100 d-flex">
                        <input value={searchText} onChange={handleSearch} className="form-control my-auto" type="text"
                            id="searchInput" name="searchInput" placeholder={t('page_tags_placeholder_search')}/>
                        <div className="dropdown my-auto" title={t(selectedSortCriteria.label)}>
                            <button className="btn mb-1 align-self-start dropdown-toggle" type="button" id="sortCrirteriaSelector" data-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={selectedSortCriteria?.icon}/>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="sortCrirteriaSelector">
                                { sortCriterias.map(sc =>
                                <li key={sc.value} onClick={() => changeSortCriteria(sc)}
                                    className="dropdown-item cursor-pointer d-flex align-items-center gap-2">
                                    <FontAwesomeIcon icon={sc.icon}/>
                                    {t(sc.label)}
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='mb-2 col col-sm-12 col-md-6 col-lg-7 d-flex gap-3 justify-content-end align-items-center'>
                    <button onClick={triggerGridListView} title={t('general_view_as_grid_or_list_titleattr')} className="btn btn-sm py-0 line-0 text-muted">
                        <span className="material-icons-round">
                            { viewAsGrid ? 'view_list' : 'grid_view' }
                        </span>
                    </button>
                    <div className="btn-group">
                        <button onClick={startForNewTag} className="btn btn-sm btn-primary bg-vitag">
                            {t('page_tags_button_createtag')}
                        </button>
                        <button title={t('page_tags_button_createtag_caret_title')} className="btn btn-sm btn-primary bg-vitag dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false">
                            <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <button onClick={exportTags} className="dropdown-item" type='button'>
                                {t('page_tags_button_exporttags')}
                            </button>
                            <button onClick={startImportTags} className="dropdown-item" type='button'>
                                {t('page_tags_button_importtags')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

                { loading &&
                    <LoadingPanel/>
                }
                { ! loading && myTags.length === 0 &&
                    <EmptyPanel message={t('page_tags_message_withouttagstoshow')}/>
                }
                { ! loading && myTags.length > 0 &&
                    <Row className='mt-3 home-container'>
                        { myTags.map(tag =>
                        <TagItem
                            key={tag.tagId}
                            leTag={tag}
                            loggedUserId={loggedUser?.userId}
                            viewAsGrid={viewAsGrid}
                            dismissTag={dismissTag}
                            startEditTag={startEditTag}
                            startShareTag={startShareTag}
                            startSharedListTag={startSharedListTag}
                            confirmCloneTag={confirmCloneTag}
                            confirmDeleteTag={confirmDeleteTag}
                            viewTagDetail={viewTagDetail} /> )}
                    </Row>
                }

                { ! loading && totalCount > 0 && 
                    <PaginationControl
                        totalCount={totalCount}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}/>
                }

        </HomeBase>

        <ConfirmModal
            modalTitle={t('modal_tags_deletetag_title')}
            modalText={t('modal_tags_deletetag_text', { tagname:currentTag?.name })}
            acceptCallback={deleteTag}
            showModal={showConfirmDeleteModal}
            setShowModal={setShowConfirmDeleteModal}/>

        <ConfirmModal
            modalTitle={t('modal_tags_clonetag_title')}
            modalText={t('modal_tags_successclonetag_text')}
            showModal={showSuccessModal}
            setShowModal={setShowSuccessModal}/>

        <ConfirmModal
            modalTitle={t('modal_tags_clonetag_title')}
            modalText={t('modal_tags_clonetag_text', { tagname:currentTag?.name })}
            acceptCallback={makeCloneTag}
            showModal={showConfirmCloneModal}
            setShowModal={setShowConfirmCloneModal}>
                <Form.Group as={Row} controlId="cloneName" className="d-flex mb-3">
                    <Form.Label className="text-wrap" column sm={12}>
                        { t('modal_tags_clonetag_label_newname') }
                    </Form.Label>
                    <Col sm={12} className="my-auto">
                        <Form.Control type="text"
                            value={cloneTagRequest.newName} onChange={changeTagNameHandler}
                            placeholder={ t('modal_tags_clonetag_placeholder_newname', {tagname:currentTag?.name}) }/>
                    </Col>
                </Form.Group>
                <Row>
                    <Col sm={12} className="mb-3">
                        <div className="form-check">
                            <input onChange={checkContextHandler} value={cloneInOtherContext} className="form-check-input" type="checkbox" id="cloneOtherContext"/>
                            <label className="form-check-label cursor-pointer mx-3" htmlFor="cloneOtherContext">
                                { t('modal_tags_clonetag_label_cloneinothercontext') }
                            </label>
                        </div>
                    </Col>
                    { cloneInOtherContext &&
                    <Col sm={12} className="mb-3">
                        <label className="form-label">
                            { t('modal_tags_clonetag_label_selectnewcontext') }
                        </label>
                        <SimpleContextSelector changeContextListener={changeSelectedContext}/>
                    </Col> }
                </Row>
        </ConfirmModal>

        <ConfirmModal
            modalTitle={ t('modal_tags_showtag_title_tag', {tagname:currentTag?.name}) }
            showOkButton={false} closeFromKeyboard={true}
            showModal={showTagDetailModal}
            setShowModal={setShowTagDetailModal}>
                <div className="d-flex flex-wrap gap-2 w-100 mb-3">
                    { currentTag?.subTags.length > 0 && <p className="w-100 mb-1">
                        { t('modal_tags_showtag_text_subtags') }
                    </p> }
                    { currentTag?.subTags.map(st =>
                        <div className="position-relative">
                            <IconPill key={st.tagId} onPillClick={() => handleTagClick(st)}
                                iconText={st.icon} iconColor={st.color}
                                pillText={st.name}
                                className='d-inline-flex cursor-pointer'/>
                            <span className="position-absolute fw-bold cartoon-text" style={{top:'-10px', right:'6px'}}>
                                {tagStats?.get(st.tagId) || 0}
                            </span>
                            <span className="position-absolute fw-bold text-dark" style={{top:'-10px', right:'6px'}}>
                                {tagStats?.get(st.tagId) || 0}
                            </span>
                        </div>
                        )}
                    { currentTag?.subTags.length == 0 &&
                        <EmptyPanel message={t('modal_tags_showtag_message_withouttags')}/> }
                </div>
        </ConfirmModal>

        <ModalButtonPad
            currentButtonPad={currentTag}
            showModal={showModalButtonPad}
            setShowModal={setShowModalButtonPad}
            savedTagListener={resetSearch}/>

        <ModalShare
            vtgTag={currentTag}
            showModal={showShareModal}
            saveVideoListener={() => handleShareByLink}
            setShowModal={setShowShareModal}/>

        <ModalSharedList
            vtgTag={currentTag}
            showModal={showSharedListModal}
            setShowModal={setShowSharedListModal}/>

        <ModalImportTags
            showModal={showModalImportTags}
            setShowModal={setShowModalImportTags}/>

        </React.Fragment>
    );
}

export { TagsHome };