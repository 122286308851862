import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GeneralContext } from '../contexts/general-context';
import { ModalChangePassword } from '../modals/ModalChangePassword';
import { doLogout } from '../services/AuthorizarionService';
import { changeProfileInfo, uploadImageToS3 } from '../services/CoreService';
import { defaultNoProfilePicture } from '../utils/ImageUtils';
import { Typologies } from '../utils/TypologiesEnum';

function ProfileTabMainInfo() {

    const [editingProfile, setEditingProfile] = useState(false);
    const [editedProfile, setEditedProfile] = useState();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const { profile, loadProfileFromBackend, subs, isProUser } = useContext(GeneralContext);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setEditedProfile(profile);
    }, [profile]);

    const saveProfileChanges = () => {
        const request = {
            firstName: editedProfile?.firstName,
            lastName: editedProfile?.lastName,
            avatarImage: editedProfile?.avatarImage
        };
        changeProfileInfo(request)
            .then(_ => {
                loadProfileFromBackend();
                setEditingProfile(false);
            })
            .catch(err => console.error('Error saving profile...', err));
    }

    const logout = () => {
        doLogout();
        history.replace('/login');
    }

    const handleFileSelection = (event) => {
        const imageFile = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setEditedProfile({...editedProfile, avatarImage: reader.result});
            uploadImageToS3(reader.result)
                .then(resp => {
                    const s3Url = resp.data.imageUrl;
                    setEditedProfile({...editedProfile, avatarImage: s3Url});
                })
                .catch(err => console.error('Impossible save image...', err));
        }
        reader.readAsDataURL(imageFile);
    }

    const putFallbackImage = () => {
        setEditedProfile({...editedProfile, avatarImage: defaultNoProfilePicture});
    }

    return <>
        <div className="w-100 p-2 p-md-3">
            <div className="position-relative">
                <img className="position-relative profile-image text-center d-block align-middle mx-auto mb-4" 
                src={editedProfile?.avatarImage} onError={putFallbackImage}/>
                {isProUser && <span className="badge-main">PRO</span>}    
            </div>
            
            <p className="mb-1 text-center"><b>{editedProfile?.nickname}</b></p>
            { ! editingProfile &&
            <React.Fragment>
                <p className="text-center mb-1">{editedProfile?.fullName}</p>
                <p className="text-center small">{editedProfile?.email}</p>
            </React.Fragment> }

            { editingProfile &&
                <div className="my-3 mx-auto row">
                    <Form.Group as={Row} controlId="profileFirstName">
                        <Form.Label className="text-right" column xs={4}>
                            { t('page_profile_section_personalinfo_label_name') }
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control type="text" value={editedProfile?.firstName}
                                onChange={(evt) => setEditedProfile({...editedProfile, firstName:evt.target.value})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="profileLastName">
                        <Form.Label className="text-right" column xs={4}>
                            { t('page_profile_section_personalinfo_label_lastname') }
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control type="text" value={editedProfile?.lastName}
                                onChange={(evt) => setEditedProfile({...editedProfile, lastName:evt.target.value})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="profileEmail">
                        <Form.Label className="text-right" column xs={4}>
                            { t('page_profile_section_personalinfo_label_email') }
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control type="email" value={editedProfile?.email} readOnly={true}
                                onChange={(evt) => setEditedProfile({...editedProfile, email:evt.target.value})}/>
                        </Col>
                    </Form.Group>
                    <div className="row">
                        <div className="col col-sm-6 offset-sm-4 col-custom-file">
                            <input onChange={handleFileSelection} type="file" accept="image/*" className="custom-file-input" id="profileImage"/>
                            <label className="mx-3 custom-file-label" style={{textAlign:'left'}} htmlFor="profileImage">
                            { t('page_profile_section_personalinfo_label_avatarpicture') }
                            </label>
                        </div>
                    </div>
                </div> }

            <div className="d-flex flex-column mx-auto" style={{maxWidth:'420px'}}>
            { ! editingProfile && <>
                <button className="btn btn-outline-dark mb-3" onClick={() => setEditingProfile(true)}>
                    { t('page_profile_section_personalinfo_button_editprofile') }
                </button>
                <button className="btn btn-outline-dark mb-3" onClick={() => setShowChangePasswordModal(true)}>
                    { t('page_profile_section_personalinfo_button_changepassword') }
                </button>
                <button className="btn btn-outline-danger mb-3" onClick={logout}>
                    { t('page_profile_section_personalinfo_button_logout') }
                </button>
            </> }
            { editingProfile && <>
                <button className="btn btn-outline-dark mb-3" onClick={saveProfileChanges}
                    disabled={editedProfile?.firstName?.length === 0 || editedProfile?.lastName?.length === 0 ? 'disabled' : ''}>
                    { t('page_profile_section_personalinfo_button_saveprofile') }
                </button>
                <button className="btn btn-secondary mb-3" onClick={() => setEditingProfile(false)}
                    disabled={editedProfile?.firstName?.length === 0 || editedProfile?.lastName?.length === 0 ? 'disabled' : ''}>
                    { t('general_cancel') }
                </button>
            </> }
            </div>
        </div>
        <ModalChangePassword
            showModal={showChangePasswordModal}
            setShowModal={setShowChangePasswordModal} />
    </>
}

export { ProfileTabMainInfo }
