import { config } from '../config';

function doLogin(userEmail, userPass, fingerprint = 'some-device') {
    const credentials = { user: userEmail, password: userPass, fingerprint };
    console.log('Trying to do login with the following credentials: ', credentials);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
    };
    return fetch(config.AUTH_DOMAIN, requestOptions).then(response => {
        if(! response.ok) {
            return Promise.reject('Invalid credentials');
        }
        return response.json();
    });
}

function overrideLogin(newOwnId, fingerprint = 'some-device') {
    const userInfo = getLoggedUser();
    const overrideRequest = { newOwnId, userId: userInfo.userId, cliId: userInfo.cliId, fingerprint };
    console.log('Trying to override login');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(overrideRequest)
    };
    return fetch(config.OVERRIDE_DOMAIN, requestOptions)
        .then(response => {
            if(! response.ok) {
                return Promise.reject('Invalid credentials');
            }
            return response.json();
        });
}

function getJsonToken() {
    let token = localStorage.getItem('nibelungo');
    if(token == null){
        return null;
    }
    token = token.replace('Bearer ', '');
    var base64Url = token.split('.')[1];
    var base64 = base64Url?.replace('-', '+')?.replace('_', '/');
    if (base64) {
        return JSON.parse(window.atob(base64));
    }
    return undefined;
}

function getLoggedUser() {
    const token = getJsonToken();
    if (token == null) {
        return null;
    }
    const user = {
        cliId : token.cli,
        ownId : token.own,
        userId : token.usr
    };
    return user;
}

function doLogout() {
    localStorage.clear();
}

export { doLogin, overrideLogin, getLoggedUser, doLogout };
