import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { Typologies } from '../utils/TypologiesEnum';
import { VideoProviderIcon } from '../general/VideoProviderIcon';
import { t } from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { uploadImageToS3 } from '../services/CoreService';

function UploaderPreview({
    vtgVideo, setVtgVideo, isEdit = false, videoDuration, setVideoDuration, videoReady, setVideoReady,
    videoUrl, videoProvider, originalThumbnail, setVideoThumbnail, videoThumbnail
}) {

    const[showOriginalImage, setShowOriginalImage] = useState(false);
    const[errorOnVideo, setErrorOnVideo] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageLoadError, setImageLoadError] = useState(false);

    useEffect(() => {
        console.log('New video URL: ' + videoUrl);
        console.log('With provider:', videoProvider);
        setShowOriginalImage(false);
        setErrorOnVideo(false);
    }, [videoUrl]);

    const getVideoThumbnail = (video) => {
        const canvas = document.createElement('canvas');
        canvas.width = video.offsetWidth;
        canvas.height = video.offsetHeight;
        const canvasContext = canvas.getContext('2d');
        canvasContext.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);
        try {
            const img = canvas.toDataURL('image/png');
            if (originalThumbnail || videoThumbnail) {
                setShowOriginalImage(true);
            } else {
                setVideoThumbnail(img);    
            }
            
        } catch (ex) {
            console.error('Error getting thumbnail', ex.message);
        }
    }   

    const videoFileReady = (event) => {
        const video = event.target;
        (originalThumbnail || videoThumbnail) ? setShowOriginalImage(true) : setShowOriginalImage(false)
        console.log('Video ready to play:', video);
        console.log('Video duration:', video.duration);
        const duration = moment.duration(video.duration, 'seconds');
        const timeInMilliseconds = duration.asMilliseconds();

        video.currentTime = video.duration * 0.4;
        setVideoDuration(duration.humanize());
        setVtgVideo({
            ...vtgVideo,
            url:videoUrl,
            timeInSeconds: duration.asSeconds(),
            timeInMilliSeconds: timeInMilliseconds,
            videoProvider,
            onlineId: 'leFile'
        });
        setVideoReady(true);
        setTimeout(() => getVideoThumbnail(video), 2000);
        
    }

    const errorLoadingVideoFile = (event) => {
        console.error(`Impossible load video ${videoUrl}`, event);
        setErrorOnVideo(true);
        setShowOriginalImage(true);
    }

    const videoLoadStart = (event) => {
        event.currentTarget.setAttribute('poster', '/assets/imgs/spinner.gif');
        event.currentTarget.classList.add('video-with-poster');
    }

    const removeVideoPoster = (event) => {
        event.currentTarget.removeAttribute('poster');
        event.currentTarget.classList.remove('video-with-poster');
    }

    const handleMouseEnter = () => {
        if (!clicked) {
          setShowAlert(true);
          setHovered(true);
        }
      };
    
      const handleMouseLeave = () => {
        if (!clicked) {
          setShowAlert(false);
          setHovered(true);
        }
      };
    
      const handleClick = () => {
        if (hovered) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
        setClicked(!clicked);
      };

      const handleImageSelection = (event) => {
        setUploadingImage(true);
        const imageFile = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            uploadImageToS3(reader.result)
                .then(resp => {
                    setUploadingImage(false);
                    const s3Url = resp.data.imageUrl;
                    const uniqueUrl = `${s3Url}?t=${new Date().getTime()}`;
                    console.log('Saved image...', uniqueUrl);
                    setVideoThumbnail(uniqueUrl);
                    setErrorOnVideo(false);
                })
                .catch(err => {
                    setUploadingImage(false);
                    console.error('Impossible save image...', err);
                });
        }
        reader.readAsDataURL(imageFile);
    }

    useEffect(() => {
        if (!errorOnVideo && (originalThumbnail || videoThumbnail)) {
            setShowOriginalImage(true);
            const checkImageLoad = (url, attempts) => {
                if(attempts == undefined || attempts <= 0) {
                    console.error('Not available attempts');
                    setErrorOnVideo(true);
                    setShowOriginalImage(false);
                    return;
                }
                const img = new Image();
                img.onload = () => {
                    setImageLoadError(false);
                    setUploadingImage(false);
                }
                img.onerror = () => {
                    setUploadingImage(true);
                    setImageLoadError(true);
                    console.log('Attemp #' + attempts);
                    setTimeout(() => {
                        checkImageLoad(url, attempts - 1);
                    }, 3000);
                }
                img.src = url;
            };
            checkImageLoad(originalThumbnail || videoThumbnail, 5);    
        }
        
    }, [originalThumbnail, videoThumbnail, showOriginalImage]);

    return (
        <>
        <div className="d-flex flex-column position-relative">
            <label>{t('modal_videomanagement_label_thumbnail')}</label>
            
            {isEdit && <span style={{zIndex:'9999', top:'50px', left:'24px', fontWeight:'bold'}}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}
                  className="px-1 position-absolute circle-badge cursor-pointer">
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color:'#ffc107', fontSize: '30px'}}/>
            </span>}
            { !errorOnVideo && uploadingImage && <div className="d-flex" style={{bottom:'8px',left:'8px'}}>
                            <div className="spinner-border spinner-border-sm my-auto"></div>
                            <span className="text-muted my-auto ml-2">
                                {t('general_loading_text')}
                            </span>
                        </div> }

            { videoReady && 
                <label htmlFor='hidden-thumbnail-upload'
                       title={t('modal_managemarker_btn_uploadcustomthumbnail_titleattr')}
                       className='fake-btn position-absolute material-icons-round'
                       style={{bottom:'0',right:'16%', zIndex:'9999',cursor:'pointer'}}>upload</label>
            }
            <input id='hidden-thumbnail-upload' onChange={handleImageSelection} type="file" accept="image/*" className='d-none'/>

            {showAlert && <Alert className="px-2 alertWarning mr-2" variant="warning">
                        {t('modal_videomanagement_warning_changevideosource')}
                    </Alert>}
            { (videoReady) ?
            <div className='position-relative border rounded' style={{backgroundColor:'#ddd'}}>
                <div className="d-flex rounded">
                    {(
                        videoProvider?.internalId === Typologies.VT_EXTERN_VIDEO
                        || videoProvider?.internalId === Typologies.VT_LOCAL_VIDEO
                    ) &&
                    ( 
                        (showOriginalImage && !imageLoadError &&
                            <img src={originalThumbnail || videoThumbnail} key={originalThumbnail || videoThumbnail} alt="Video" className="w-100 mx-auto rounded"
                                style={{minHeight: '280px', maxHeight:'280px', backgroundColor:'#ddd', objectFit:'contain'}}/>)
                        ||
                        (showOriginalImage && imageLoadError &&
                            <img alt=""  
                                    className="d-block border w-100 mx-auto" 
                                    style={{minHeight: '80px', maxHeight:'280px',objectFit:'contain',backgroundColor:'#ececec'}}>
                            
                            </img>
                        )
                        ||
                        (errorOnVideo
                            ? <div className='w-100 bg-white rounded py-4'>
                                <div className="w-100 bg-light px-3 py-3 d-flex align-items-center justify-content-center gap-2">
                                    <img src="/assets/imgs/broke-video-2.png" style={{objectFit:'contain',width:'70px',opacity:'0.5'}}/>
                                    <span className="text-muted text-wrapped text-center" style={{maxWidth:'120px'}}>
                                        {t('modal_videomanagement_error_videoupload')}
                                    </span>
                                </div>
                            </div>
                            : <video id="videoFile" src={videoUrl} style={{maxHeight:'320px'}}
                                height="auto" crossOrigin="anonymous" className='w-100 mx-auto rounded'
                                onLoadStart={videoLoadStart} onCanPlay={removeVideoPoster}
                                onLoadedMetadata={videoFileReady} onError={errorLoadingVideoFile}/>
                        )
                    )}
                    { (
                        videoProvider?.internalId === Typologies.VT_YOUTUBE
                        || videoProvider?.internalId === Typologies.VT_VIMEO
                        || videoProvider?.internalId === Typologies.VT_TWITCH
                    ) &&

                    (((originalThumbnail || videoThumbnail) && !imageLoadError &&
                        <img src={originalThumbnail || videoThumbnail} key={originalThumbnail || videoThumbnail} alt="Video" className="w-100 mx-auto rounded"
                            style={{minHeight: '280px', maxHeight:'280px', backgroundColor:'#ddd', objectFit:'contain'}}/>)
                    ||
                    ((originalThumbnail || videoThumbnail) && imageLoadError &&
                        <img alt=""  
                                className="d-block border w-100 mx-auto" 
                                style={{minHeight: '80px', maxHeight:'280px',objectFit:'contain',backgroundColor:'#ececec'}}>
                        
                        </img>
                    ) ||
                    <img src={vtgVideo.miniature} alt="Video" className="w-100 mx-auto rounded"
                        style={{maxHeight:'280px', backgroundColor:'#ddd', objectFit:'contain'}}/>) }
                </div>
                <span style={{zIndex:'9999', bottom:'16px', left:'24px', fontWeight:'bold'}}
                    className="py-1 px-2 position-absolute rounded bg-light text-dark">
                    <VideoProviderIcon videoProvider={videoProvider}/> {videoDuration}
                </span>
            </div>
            :
            <div className='rounded border'>
                <div className="bg-light p-3"></div>
                <div className="bg-white py-5">
                    <div className="d-flex p-3 text-muted mx-auto">
                        <span className="ml-auto mr-2 material-symbols-outlined">subscriptions</span>
                        <span className="mr-auto my-auto">
                            {t('modal_videomanagement_placeholder_thumbnail')}
                        </span>
                    </div>
                </div>
                <div className="bg-light p-3"></div>
            </div> }
        </div>
        </>
    );
}

export { UploaderPreview };