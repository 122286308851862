import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PREFERENCES, savePreference } from "../utils/preferences/user-preferences";

function PaginationControl({
    currentPage, setCurrentPage,
    pageSize = 4, setPageSize, totalCount,
    paginationChangeListener = () => console.log('Pagination change')
}) {

    const [pagesArray, setPagesArray] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const n = Math.ceil(totalCount / pageSize);
        const pages = Array
            .from({length: n}, (_, i) => i)
            // .map(i => (i === 0 || i === n - 1 || (i > currentPage - 2 && i < currentPage + 2)) ? i : i + 'a')
            ;
        setPagesArray(pages);
    }, [totalCount, pageSize, currentPage]);

    const nextPage = () => {
        const page = currentPage + 1;
        setCurrentPage(page);
        paginationChangeListener(page, pageSize);
    }

    const prevPage = () => {
        const page = currentPage - 1;
        setCurrentPage(page);
        paginationChangeListener(page, pageSize);
    }

    const changePageSize = (evt) => {
        const newPageSize = evt.target.value;
        setPageSize(newPageSize);
        savePreference(PREFERENCES.PAGE_SIZE, newPageSize);
        setCurrentPage(0);
        paginationChangeListener(0, newPageSize);
    }

    const selectPage = (page) => {
        setCurrentPage(page);
        paginationChangeListener(page, pageSize);
    }

    return (
        <div className='w-100 d-flex gap-3 flex-wrap mx-auto mt-4 justify-content-center'>
            <ul className="pagination m-0">
                <li className='page-item border-none'>
                    <button onClick={prevPage}
                        className={"btn page-link border-none " + (currentPage === 0 ? 'disabled' : '')}
                        disabled={currentPage === 0}>
                        <span className="material-icons-round my-auto">chevron_left</span>
                    </button>
                </li>
                { pagesArray.map(pag =>
                    { return (pag <= 2 || pag >= pagesArray.length - 2 || Math.abs(pag - currentPage) <= 2) ?
                    <li key={pag} className="page-item border-none">
                        <button onClick={() => selectPage(pag)}
                            title={t('pagination_current_of_total', {n: pag + 1, total: pagesArray.length})}
                            className={"btn page-link my-auto border-none " + (pag === currentPage ? 'active' : '')}>
                            {pag + 1}
                        </button>
                    </li> : <span key={pag} className="show-only-first px-3">...</span>}
                )}           
                <li className="page-item border-none">
                    <button onClick={nextPage}
                        className={"btn page-link border-none " + (currentPage === pagesArray.length - 1 ? 'disabled' : '')}
                        disabled={currentPage === pagesArray.length - 1}>
                        <span className="material-icons-round my-auto">chevron_right</span>
                    </button>
                </li>
            </ul>
            <select value={pageSize} onChange={changePageSize} className="form-control my-auto" style={{width:'initial'}}>
                <option value={8}>{t('pagination_show_n_items', {n:8})}</option>
                <option value={12}>{t('pagination_show_n_items', {n:12})}</option>
                <option value={20}>{t('pagination_show_n_items', {n:20})}</option>
            </select>
        </div>
  );
}

export { PaginationControl }