import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { restoreTrainingTags } from "../services/VitagTagsService";
import { restoreTrainingVideos } from "../services/VitagVideosService";
import { useCustomToast } from "../utils/toasts/useCustomToast";
import { LanguageSelector } from "../utils/translations/LanguageSelector";
import { OffsetSelector } from "../utils/others/OffsetSelector";

function ProfileTabOtherOptions() {

    const { t } = useTranslation();
    const { addToast } = useCustomToast();

    const restoreTrainingMaterial = () => {
        Promise.all([restoreTrainingVideos(), restoreTrainingTags()])
        .then(_ => {
            console.log('All training material has been restored');
            addToast({
                header: t('toast_restoretrainingmaterial_title'),
                body: t('toast_restoretrainingmaterial_text'),
                variant: "success"
            });
        })
        .catch(err => {
            console.error('Error restoring training resources', err);
        })
    }

    return <>
        <div className="d-flex flex-column mx-auto p-2 p-md-5">
            <button className="btn btn-secondary" onClick={restoreTrainingMaterial}>
                { t('page_profile_section_config_button_restoretrainingmaterial') }
            </button>
            <div className="border-bottom my-4 w-100"></div>
            <p>{ t('page_profile_section_config_selectlanguage') }</p>
            <LanguageSelector/>
            <div className="border-bottom my-4 w-100"></div>
            <p>{ t('page_profile_section_config_changeOffsets') }</p>
            <OffsetSelector/>
            <div className="border-bottom my-4 w-100"></div>
            <Alert variant="danger">
                <div className="d-flex">
                    <span className="material-icons-round my-auto">warning</span>
                    <div className="d-flex flex-column ml-3">
                        <strong>{ t('page_profile_section_config_alertdanger_lookout') }</strong>
                        <p className="mb-0">{ t('page_profile_section_config_alertdanger_dangerarea') }</p>
                    </div>
                </div>
            </Alert>
            <button className="btn btn-danger disabled" disabled>
                { t('page_profile_section_config_button_deleteaccount') }
            </button>
        </div>
    </>

}

export { ProfileTabOtherOptions }
