import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { convertSecondsToTime } from '../../utils/NabUtils';
import { addMarkerAtVideo } from '../../services/VitagMarkersService';
import { listAllTags } from '../../services/VitagTagsService';
import { MarkersBar } from '../MarkersBar';
import { FavoritesBar } from '../FavoritesBar';
import { ModalShare } from '../../modals/ModalShare';
import { getPreferenceAsBool, PREFERENCES, savePreference } from '../../utils/preferences/user-preferences';
import { useTranslation } from 'react-i18next';
import { ProfileIndicator } from '../../general/ProfileIndicator';
import { getVideoDetail } from '../../services/VitagVideosService';
import { listFavoriteTagsByVideo } from '../../services/VitagFavoritesService';
import urlParser from 'nab-js-video-url-parser';
import { config } from '../../config';
import { MarkerSummary } from '../../general/MarkerSummary';
import { ModalManageFavs } from '../../modals/ModalManageFavs';

function LivePlayer() {

    let { hashId } = useParams();
    const history = useHistory();
    const [vtgVideo, setVtgVideo] = useState();
    const [needRefresh, setNeedRefresh] = useState();
    const [repaintMarkers, setRepaintMarkers] = useState();
    const [availableVtgTags, setAvailableVtgTags] = useState([]);
    const [favoriteVtgTags, setFavoriteVtgTags] = useState([]);
    const [quickTag, setQuickTag] = useState(getPreferenceAsBool(PREFERENCES.USE_TURBO));
    const [showShareModal, setShowShareModal] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [markerSummary, setMarkerSummary] = useState();
    const [recentMarkers, setRecentMarkers] = useState([]);
    const [currentTag, setCurrentTag] = useState();
    const [currentMarker, setCurrentMarker] = useState();
    const [currentThumbnail, setCurrentThumbnail] = useState('');
    const [currentTime, setCurrentTime] = useState(0);
    const [currentDetailTime, setCurrentDetailTime] = useState('');
    const [showSideBar, setShowSideBar] = useState(false);
    const [showModalManageMarker, setShowModalManageMarker] = useState(false);
    const [showModalViewMarker, setShowModalViewMarker] = useState(false);
    const [showModalManageFavs, setShowModalManageFavs] = useState(false);
    const [showHelpText, setShowHelpText] = useState(false);
    const [livePlaying, setLivePlaying] = useState(true);
    const { t } = useTranslation();
    const location = useLocation();
    const [lastPlaying, setLastPlaying] = useState()
    
    useEffect(() => {
        Promise.all([getVideoDetail(hashId), listAllTags()])
            .then(allResp => {
                const leVideo = allResp[0].data;
                document.title = config.TITLE_BASE + leVideo.title;
                setVtgVideo(leVideo);
                setAvailableVtgTags(allResp[1].data);
                analizeParseUrl(leVideo.referenceUrl);
                return listFavoriteTagsByVideo(leVideo.videoId);
            }).then(favs => {
                const transformedData = favs.data.map(item => {
                    return { position: item.position, ...item.tag };
                });
                transformedData.sort((a, b) => a.position - b.position);
                setFavoriteVtgTags(transformedData);
            }).catch(err => {
                console.error("Error getting initial data", err);
            });
        setCurrentTime(
            (location.state?.start || 0) * 1000
        );
    }, [location, hashId]);

    useEffect(() => {
        let interval;
        if (livePlaying) {
          interval = setInterval(() => {
            setCurrentTime((prevTime) => prevTime + 100);
          }, 100);
        } else {
          clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [livePlaying]);

    const analizeParseUrl = (referenceUrl) => {
        const info = urlParser.parse(referenceUrl);
        if (info == undefined) {
            console.error('Error parsing video URL');
            setShowHelpText(true);
            return;
        }
        if (info.provider.toLowerCase() === 'twitch' && info.mediaType == 'stream') {
            setShowHelpText(false);
            const options = {width:'100%', height:'100%', channel:info.channel};
            var player = new window.Twitch.Player('twitch-player', options);
            return;
        }
        setShowHelpText(true);
    }

    const reloadMarkers = () => {
        setNeedRefresh(new Date());
    }

    const newMarkerListener = (newMarker) => {
        console.log('New marker on videoPlayer', newMarker);
        setRecentMarkers([...recentMarkers, newMarker.videoMarkerId]);
        setNeedRefresh(newMarker);
    }

    const handleQuickMarkerClick = (event, tag) => {
        if (quickTag || event.shiftKey) {
            addQuickTagAtVideo(tag);
            return;
        }
        setCurrentMarker(null);
        setCurrentTag(tag);
        // openNewMarkerModal();
    }

    const handleClickExtendMarker = () => {
        setCurrentTag(null);
        // openNewMarkerModal(3);
    }

    const extractCurrentTime = (seconds) => {
        console.log('Extracting current time: ' + seconds);
        const detailTime = convertSecondsToTime(seconds);
        setCurrentTime(seconds);
        setCurrentDetailTime(detailTime);
    }

    const getCurrentTime = () => {
        return new Promise(r => r(currentTime / 1000));
    }

    const addQuickTagAtVideo = (leTag) => {
        getCurrentTime().then(ct => {
            const quickTag = {
                videoId: vtgVideo?.videoId,
                title: leTag.name,
                description: '',
                startSecond: ct,
                markerTags:[{vtgTag:leTag}]
            };
            const thumbnail = getVideoThumbnail();
            // if (thumbnail.startsWith('data:image')) {
            //     uploadImageToS3(thumbnail)
            //         .then(resp => {
            //             quickTag.thumbnail = resp.data.imageUrl;
            //             addTagAtVideo(quickTag);
            //         })
            //         .catch();
            //     return;
            // }
            quickTag.thumbnail = thumbnail;
            addTagAtVideo(quickTag);
        });
    }

    const addTagAtVideo = (newTag) => {
        addMarkerAtVideo(newTag)
        .then(resp => {
            const tagAtVideo = resp.data;
            newMarkerListener(tagAtVideo);
        })
        .catch(err => console.error('Error adding tag', err));
    }

    const shareVideo = () => setShowShareModal(true);

    const triggerSideBar = () => {
        document.getElementById('markersSideBar').style.transform = showSideBar ? 'translateX(280px)' : 'translateX(-280px)';
        setShowSideBar(! showSideBar);
    }

    const getVideoThumbnail = () => {
        // if (vtgVideo?.videoProvider.internalId === Typologies.VT_YOUTUBE) {
        //     return vtgVideo?.miniature;
        // }
        // if (vtgVideo?.videoProvider.internalId === Typologies.VT_VIMEO) {
        //     return vtgVideo?.miniature;
        // }
        // if (vtgVideo?.videoProvider.internalId === Typologies.VT_TWITCH) {
        //     return vtgVideo?.miniature;
        // }
        return vtgVideo?.miniature;
    }

    const startMarkerEdit = (marker) => {
        // setMarkerSummary(undefined);
        setCurrentTag(null);
        setCurrentMarker(marker);
        setCurrentThumbnail(marker.thumbnail);
        // setCurrentTime(marker.startSecond);
        // setCurrentDetailTime(convertSecondsToTime(marker.startSecond));
        setShowModalManageMarker(true);
    }

    const showMarkerDetail = (marker) => {
        // setMarkerSummary(undefined);
        setCurrentMarker(marker);
        setCurrentThumbnail(marker.thumbnail);
        setCurrentDetailTime(convertSecondsToTime(marker.startSecond));
        setShowModalViewMarker(true);
    }

    const changeQuickTag = (qt) => {
        savePreference(PREFERENCES.USE_TURBO, qt);
        setQuickTag(qt);
    }

    const moveToFake = (seconds) => {
        console.log(`Move to ${seconds}? No, no... is a live stream`);
    }

    const triggerLiveTime = () => {
        setLivePlaying(! livePlaying);
    }

    const handleShareByLink = (shareByLink) => {
        setVtgVideo(vtgVideo => ({...vtgVideo, shareByLink}));
    }

    return (
        <React.Fragment>
            <div className="video-player-container">
                <div className="ui-elements-container">
                    <div className="top-navigation px-4 py-2 d-flex justify-content-between" style={{zIndex:555}}>
                        <div className="d-flex align-items-center w-75">
                            <Link to="/home" className="mr-3">
                                <img src="/assets/imgs/logo-white-and-color.svg" alt="Logo ViTAG" height="42px"/>
                            </Link>
                            <div className="d-flex flex-column align-items-start border-left pl-3 py-1">
                                <p className="text-white mb-1 video-title">{vtgVideo?.title}</p>
                                { vtgVideo?.shareType === 'owner' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'onlyread' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'individual' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                                { vtgVideo?.shareType === 'collaborative' && 
                                    <span style={{width:'auto'}} className="badge badge-pill text-white bg-vitag py-2 px-3">
                                        { t('general_sharetype_' + vtgVideo?.shareType) }
                                    </span>}
                            </div>
                        </div>
                        <div className="text-white d-flex gap-3 align-items-center">
                            { vtgVideo?.shareType === 'owner' &&
                            <button onClick={shareVideo} id="share-btn" title={t('player_navbar_btn_sharevideo_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round">share</span>
                            </button> }
                            <button onClick={triggerSideBar} id="tag-list-btn" title={t('player_navbar_btn_sidebartoggle_titleattr')}
                                className="btn btn-sm btn-white-text line-0">
                                <span className="material-icons-round" style={{fontSize:'28px'}}>search</span>
                            </button>
                            <ProfileIndicator useDarkTheme={false}/>
                        </div>
                    </div>

                    { vtgVideo?.shareType && vtgVideo?.shareType !== 'onlyread' && 
                        <FavoritesBar
                            fromLive={true}
                            quickTag={quickTag}
                            setQuickTag={changeQuickTag}
                            favoriteVtgTags={favoriteVtgTags}
                            listenerOpenFavs={() => setShowModalManageFavs(true)}
                            // listenerNewTag={() => setShowNewTagModal(true)}
                            listenerOpenNewMarker={handleClickExtendMarker}
                            listenerNewQuickMarker={handleQuickMarkerClick}>
                            <div className="d-flex gap-2">
                                <button className='btn px-2 d-flex btn-sm btn-white-text line-0 p-0' onClick={triggerLiveTime}>
                                    {livePlaying ? 
                                        <span className="my-auto text-danger">LIVE</span> : 
                                        <span className="my-auto text-secondary">OFF</span> }
                                    <span className={"material-icons-round " + (livePlaying ? 'text-danger' : 'text-secondary')}>
                                        radio_button_checked
                                    </span>
                                </button>
                                <p className="m-0 text-white text-monospace">
                                    {("0" + Math.floor((currentTime / 60000) % 60)).slice(-2)}:
                                    {("0" + Math.floor((currentTime / 1000) % 60)).slice(-2)}:
                                    {("" + ((currentTime / 10) % 100)).substring(0,1)}
                                </p>
                            </div>
                        </FavoritesBar> }

                </div>

                <div id="markersSideBar" className="">
                    <MarkersBar
                        videoHashId={hashId}
                        videoDuration={currentTime / 1000}
                        videoProvider={vtgVideo?.videoProvider}
                        videoUserId={-1}
                        markers={markers}
                        setMarkers={setMarkers}
                        markerSummary={markerSummary}
                        setMarkerSummary={setMarkerSummary}
                        shareType={vtgVideo?.shareType}
                        startEditListener={startMarkerEdit}
                        fromLive={true}
                        // startDeleteListener={confirmDeleteMarker}
                        onlyShowListener={showMarkerDetail}
                        closeSidebarListener={triggerSideBar}
                        moveToListener={moveToFake}
                        repaintMarkers={repaintMarkers}
                        recentMarkers={recentMarkers}
                        setRecentMarkers={setRecentMarkers}
                        refresh={needRefresh} />
                </div>

                { markerSummary &&
                <MarkerSummary
                    marker={markerSummary}
                    setMarker={setMarkerSummary}
                    updateMarkersListener={reloadMarkers}
                    onCloseMarkerSummary={() => setMarkerSummary(undefined)}/>}

                <div id="twitch-player" className="position-absolute w-100 vh-100">
                </div>

                { showHelpText &&
                <div id="help-text" className="d-flex flex-column gap-2 justify-content-center align-items-center position-absolute w-100 vh-100">
                    <span className="text-light h3">{t('page_liveplayer_home_text1')}</span>
                    <span className="text-light h3">{t('page_liveplayer_home_text2')}</span>
                    <span className="text-light h3">{t('page_liveplayer_home_text3')}</span>
                    <span className="text-light h3">{t('page_liveplayer_home_text4')}</span>
                    <span className="text-light h3">{t('page_liveplayer_home_text5')}</span>
                </div> }

            </div>

            <div className="position-absolute w-100 px-3 pb-3" style={{bottom:0}}>
                <div id="live-progress-bar" className='w-100' style={{height:'4px',backgroundColor:'white'}}></div>
            </div>

            <ModalShare
                vtgVideo={vtgVideo}
                showModal={showShareModal}
                saveVideoListener={handleShareByLink}
                setShowModal={setShowShareModal}/>

            <ModalManageFavs
                videoId={vtgVideo?.videoId}
                availableTags={availableVtgTags}
                currentFavs={favoriteVtgTags}
                setFavorites={setFavoriteVtgTags}
                showModal={showModalManageFavs}
                setShowModal={setShowModalManageFavs}/>


        </React.Fragment>
    );
}

export { LivePlayer };
