import React from 'react';
import { useTranslation } from 'react-i18next';

function ProfileSelector({ availableUsers, setAvailableUsers, filterUsers, setFilterUsers, ownerUserId }) {

    const { t } = useTranslation();

    const handleClick = (currentProfile, position) => {
        currentProfile.selected = ! currentProfile.selected;
        const newUsers = [...availableUsers];
        newUsers[position] = currentProfile;
        setAvailableUsers(newUsers);
        setFilterUsers(newUsers.filter(usr => usr.selected).map(usr => usr.userId));
    }

    const cleanFilter = () => {
        const newUsers = [...availableUsers];
        newUsers.forEach(usr => usr.selected = false);
        setAvailableUsers(newUsers);
        setFilterUsers([]);
    }

    return (
        <React.Fragment>
        { availableUsers.length > 0 ?
            <div className="d-flex justify-content-start border-bottom gap-1 px-2 py-2 w-100" style={{flexDirection:'row', overflowX:'auto'}}>
                { availableUsers.filter(au => au.selected).length > 0 &&
                    <button title={t('player_sidebar_btn_cleanfilter_titleattr')}
                        onClick={cleanFilter} style={{lineHeight:1}}
                        className='btn btn-sm mr-1 disabled btn-outline-secondary'>
                        <span className="material-icons-round" style={{fontSize:'20px'}}>filter_alt_off</span>
                    </button>}
                { availableUsers.map((profile, i) =>
                    <div key={profile?.userId} onClick={() => handleClick(profile, i)} title={profile?.fullName}
                        className={'nab-circle-btn profile-container mx-0 d-flex ' + (profile?.selected ? 'nab-boxing' : '')}
                        style={{flexShrink:'0', cursor:'pointer', backgroundColor:((profile?.color || '#3c3c3c') + (profile?.selected ? '' : '66'))}}>
                        { profile?.avatarImage ?
                            <img src={profile?.avatarImage} className="w-100 rounded-circle bg-light" alt="T.T" style={{width:'36px',height:'36px',objectFit:'contain'}}/> :
                            <span className='my-auto mx-auto'>
                                { profile?.fullName.split(' ').map(fn => fn.charAt(0)).join('').substring(0, 2) }
                            </span>}
                        { ownerUserId === profile.userId &&
                            <div className="owner-indicator" title={t('player_sidebar_profileselector_ownerindicator_titleattr')}></div> }
                    </div>)}
            </div> :
            <span className="border-bottom py-3 text-muted d-block w-100 text-center small">
                {t('player_sidebar_message_nouserstofilter')}
            </span> }
        </React.Fragment>
    );
}

export { ProfileSelector }
