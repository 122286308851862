import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './TwitchPlayer.css';

function TwitchPlayer({
        twitchVideoId,
        videoPlaying, setVideoPlaying,
        currentVideoTime, setCurrentVideoTime,
        externSeekTo, externSetTwitchPlayer
    }) {

    const [twitchPlayer, setTwitchPlayer] = useState();
    const [twitchIsPlaying, setTwitchIsPlaying] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const options = {
            width:'100%', height:'100%',
            video:twitchVideoId
        };
        var player = new window.Twitch.Player('twitch-player', options);

        player.addEventListener(window.Twitch.Player.PAUSE, _ => {
            setTwitchIsPlaying(false);
            setVideoPlaying(false);
        });
        player.addEventListener(window.Twitch.Player.PLAY, _ => {
            setTwitchIsPlaying(true);
            setVideoPlaying(true);
        });
        player.addEventListener(window.Twitch.Player.SEEK, seek => {
            console.log('Twitch seek to:', seek);
            setCurrentVideoTime(seek.position);
            if(! videoPlaying) {
                player.play();
                setTimeout(() => player.pause(), 10);
            }
        });
        player.addEventListener(window.Twitch.Player.READY, ready => {
            console.log('Twitch player is ready', ready);
            player.pause();
            // player.getDuration().then(duration => {
            //     changeVideoDuration(duration);
            //     changeVideoCurrentTime(0.0);
            // });
            const newTime = location.state?.last || 0;
            player.seek(newTime);
        });
        setTwitchPlayer(player);
        externSetTwitchPlayer(player);
    }, []);

    useEffect(() => {
        if(! twitchPlayer) {
            console.log('Not ready for interval');
            return;
        }
        console.log('Adding interval');
        const interval = setInterval(() => {
            const time = twitchPlayer?.getCurrentTime();
            setCurrentVideoTime(time);
        }, 600);
        return () => {
            console.log('Cleaning interval');
            clearInterval(interval);
        };
    }, [twitchPlayer]);

    useEffect(() => {
        videoPlaying ? twitchPlay() : twitchPause();
    }, [videoPlaying]);

    useEffect(() => {
        console.log('Extern seek-to: ' + externSeekTo);
        twitchSeekTo(externSeekTo);
    }, [externSeekTo]);

    const twitchPlay = () => {
        twitchPlayer?.play();
    }

    const twitchPause = () => {
        twitchPlayer?.pause();
    }

    const twitchSeekTo = newTime => {
        setCurrentVideoTime(newTime);
        twitchPlayer?.seek(newTime);
    }

    return (
        <>
            <div id="twitch-player" className="position-absolute w-100 vh-100">
            </div>
            <div id="twitch-player-markers-line-container" className="position-absolute w-100">
                <div id="twitch-player-markers-line"></div>
            </div>
        </>
    );
}

export { TwitchPlayer }