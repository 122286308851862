import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ConfirmModal({
    modalTitle="modal_confirm_title", modalText="", modalSize="lg", customClasses='',
    okText='modal_confirm_ok', okIcon='check', acceptCallback, showOkButton = true,
    cancelText='modal_confirm_cancel', cancelIcon='close', cancelIconRound = true,
    cancelCallback, showCancelButton = true, okIconRound = true, okIconSize = '24px',
    cancelIconSize = '24px', showModal, setShowModal, closeFromKeyboard = false, 
    closeOnSuccess = true, translateModalTitle = false, translateModalText = false, 
    translateModalButtons = true, children
}) {

    const { t } = useTranslation();

    const completeAcceptCallback = () => {
        acceptCallback && acceptCallback();
        if (closeOnSuccess) {
            closeThisModal();
        }
    }

    const completeCancelCallback = () => {
        cancelCallback && cancelCallback();
        closeThisModal();
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    return (
    <Modal show={showModal} onHide={closeThisModal} size={modalSize}
        backdrop="static" keyboard={closeFromKeyboard}
        dialogClassName={"pt-5 " + customClasses}>
        <Modal.Header className={(! showCancelButton && " pl-5 ")}>
            { showCancelButton ?
            <button onClick={completeCancelCallback} className="btn btn-sm text-muted"
                title={ translateModalButtons ? t(cancelText) : cancelText }>
                { cancelIconRound ? 
                    <span className='material-icons-round' style={{fontSize: cancelIconSize}}>{cancelIcon}</span> :
                    <span className='material-symbols-outlined' style={{fontSize: cancelIconSize}}>{cancelIcon}</span>
                }
            </button> : <span className="btn-placeholder"></span> }
            <Modal.Title className="mx-auto">{ translateModalTitle ? t(modalTitle) : modalTitle }</Modal.Title>
            { showOkButton ?
            <button onClick={completeAcceptCallback} className="btn btn-sm text-vitag"
                title={ translateModalButtons ? t(okText) : okText }>
                { okIconRound ? 
                    <span className='material-icons-round' style={{fontSize:okIconSize}}>{okIcon}</span> :
                    <span className='material-symbols-outlined' style={{fontSize:okIconSize}}>{okIcon}</span>
                }
            </button> : <span className="btn-placeholder"></span> }
        </Modal.Header>
        <Modal.Body className="px-5">
            { modalText && <p className="my-2">{ translateModalText ? t(modalText) : modalText }</p> }
            {children}
        </Modal.Body>
    </Modal>);
}

export { ConfirmModal };
