import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import * as CKE_es from '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import { getLoggedUser } from "../services/AuthorizarionService";
import { convertSecondsToTime, convertMillisecondsToTime } from "../utils/NabUtils";
import { getPreference, PREFERENCES, savePreference } from "../utils/preferences/user-preferences";
import { IconPill } from "./IconPill";
import { updateMarkerAtVideo } from "../services/VitagMarkersService";
import { configToOpenLinks } from "../utils/CKEditorConfig";
import { Spinner } from "react-bootstrap";
import { CommentsPanel } from "./CommentsPanel";

function MarkerSummary ({
    marker, setMarker, videoId,
    startEditListener, startDeleteListener, updateMarkersListener, onCloseMarkerSummary
}) {

    const { t, i18n } = useTranslation();
    const lastTimeoutIdTitle = useRef(-1);
    const lastTimeoutIdDescription = useRef(-1);
    const [loggedUser] = useState(getLoggedUser());
    const [quickEditing, setQuickEditing] = useState(false);
    const [allowEditing, setAllowEditing] = useState(false);
    const [editingTitle, setEditingTitle] = useState(false);
    const [time, setTime] = useState();
    const [descriptionCopy, setDescriptionCopy] = useState();
    const [titleCopy, setTitleCopy] = useState();
    const [saveStatus, setSaveStatus] = useState(OpStatus.INIT);
    const [lang, setLang] = useState('en');
    const [showComments, setShowComments] = useState(false);
    const [posLeft, setPosLeft] = useState(
        getPreference(PREFERENCES.MARKER_SUMMARY_LEFT)
    );
    const [posTop, setPosTop] = useState(
        getPreference(PREFERENCES.MARKER_SUMMARY_TOP)
    );
    const [opacity, setOpacity] = useState(
        getPreference(PREFERENCES.MARKER_SUMMARY_OPACITY)
    );
    const inputRefTitle = useRef(null);

    useEffect(() => {
        console.log('Current lang', i18n.language, CKE_es);
        if (marker) {
            setTime(marker?.startMilliSecond > 0 ? convertMillisecondsToTime(marker?.startMilliSecond, false): convertSecondsToTime(marker?.startSecond));
            setDescriptionCopy(marker.description);
            setTitleCopy(marker.title);
            if (! marker.saved) {
                setSaveStatus(OpStatus.INIT);
            }
        }
        setAllowEditing(loggedUser.userId === marker?.userId);
        // setQuickEditing(false);
    }, [marker]);

    useEffect(() => {
        if (marker) {
            setEditingTitle(true);
            const timer = setTimeout(() => {
                if (inputRefTitle.current) {
                    inputRefTitle.current.focus();
                }
            }, 200);
            return () => clearTimeout(timer);
        }
      }, [marker]);

    useEffect(() => {
        setLang(i18n.language.substring(0, 2));
    }, [i18n.language]);

    useEffect(() => {
        const elmnt = document.getElementById('marker-summary');
        if(! elmnt || ! posLeft || ! posTop) {
            console.warn('Invalid setup for marker-summary UI');
            return;
        }
        if (posLeft && (posLeft.replace('px', '') > window.innerWidth)) {
            setPosLeft('50px');
            savePreference(PREFERENCES.MARKER_SUMMARY_LEFT, '50px');
            elmnt.style.left = "50px";
        }
        if (posTop && (posTop.replace('px', '') > window.innerHeight)) {
            setPosTop('50px');
            savePreference(PREFERENCES.MARKER_SUMMARY_TOP, '50px');
            elmnt.style.top = "50px";
        }
    }, [marker]);

    const safeStartEditListener = () => {
        startEditListener && startEditListener({...marker, title:titleCopy, description:descriptionCopy});
        setMarker(undefined);
    }

    const safeStartDeleteListener = () => {
        startDeleteListener && startDeleteListener(marker);
        setMarker(undefined);
    }

    const safeOnCloseMarkerSummary = () => {
        onCloseMarkerSummary && onCloseMarkerSummary();
    }

    const safeUpdateMarkersListener = () => {
        updateMarkersListener && updateMarkersListener(marker);
    }

    let posFavbar1 = 0, posFavbar2 = 0, posFavbar3 = 0, posFavbar4 = 0;

    const startFavbarMove = evt => {
        evt = evt || window.event;
        evt.preventDefault();
        posFavbar3 = evt.clientX;
        posFavbar4 = evt.clientY;
        document.onmouseup = endFavbarMove;
        document.onmousemove = moveFavbar;
    }

    const moveFavbar = evt => {
        evt = evt || window.event;
        evt.preventDefault();
        posFavbar1 = posFavbar3 - evt.clientX;
        posFavbar2 = posFavbar4 - evt.clientY;
        posFavbar3 = evt.clientX;
        posFavbar4 = evt.clientY;
        const elmnt = document.getElementById('marker-summary');
        if (elmnt) {
            elmnt.style.top = (elmnt.offsetTop - posFavbar2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - posFavbar1) + "px";
            elmnt.style.right = 'auto';
            elmnt.style.bottom = 'auto';
        }
    }

    const endFavbarMove = () => {
        document.onmousemove = null;
        const elmnt = document.getElementById('marker-summary');
        if (elmnt) {
            setPosLeft(elmnt.style.left);
            savePreference(PREFERENCES.MARKER_SUMMARY_LEFT, elmnt.style.left);
            setPosTop(elmnt.style.top);
            savePreference(PREFERENCES.MARKER_SUMMARY_TOP, elmnt.style.top);
        }
    }

    const triggerOpacity = () => {
        let newOpacity = 0;
        if (opacity == 0) {
            newOpacity = 1;
        } else if (opacity == 1) {
            newOpacity = 2;
        }
        setOpacity(newOpacity);
        savePreference(PREFERENCES.MARKER_SUMMARY_OPACITY, newOpacity);
    }

    const startQuickEditing = () => {
        if(allowEditing) {
            setQuickEditing(true);
        }
    }

    const cancelQuickEditing = () => {
        setQuickEditing(false);
        setDescriptionCopy(marker?.description);
    }

    const saveMarkerChange = (newTitle, newDescription) => {
        updateMarkerAtVideo({...marker, description:newDescription, title:newTitle}, marker.videoMarkerId)
            .then(_ => {
                console.log('Marker updated successful');
                // setMarker({...marker, description:newDescription, title:newTitle, saved:true});
                // setQuickEditing(false);
                // setEditingTitle(false);
                safeUpdateMarkersListener();
                // addToast({
                //     body: t('markersummary_toast_successsaving'),
                //     variant: 'success'
                // });
                setSaveStatus(OpStatus.SUCCESS);
            })
            .catch(err => {
                console.error('Error updating marker', err);
                setSaveStatus(OpStatus.ERROR);
                // addToast({
                //     body: t('markersummary_toast_errorsaving'),
                //     variant: 'error'
                // });
            });
    }

    const handleMarkerTitleChange = (evt) => {
        setSaveStatus(OpStatus.LOADING);
        const newTitle = evt.target.value;
        setTitleCopy(newTitle);
        if (lastTimeoutIdTitle.current !== -1) {
            clearTimeout(lastTimeoutIdTitle.current);
        }
        lastTimeoutIdTitle.current = setTimeout(() => {
            saveMarkerChange(newTitle, descriptionCopy);
        }, 1200);
    }

    const handleMarkerDescriptionChange = (newDescription) => {
        setSaveStatus(OpStatus.LOADING);
        setDescriptionCopy(newDescription);
        if (lastTimeoutIdDescription.current !== -1) {
            clearTimeout(lastTimeoutIdDescription.current);
        }
        lastTimeoutIdDescription.current = setTimeout(() => {
            saveMarkerChange(titleCopy, newDescription);
        }, 1200);
    }

    const enableTitleEdit = () => {
        if(allowEditing) {
            setEditingTitle(true);
        }
    }

    const cancelTitleEdit = () => {
        setEditingTitle(false);
        setTitleCopy(marker.title);
    }

    const closeSummary = () => {
        setMarker(undefined);
        safeOnCloseMarkerSummary();
    }

    return ! marker ? null :
    <div id="marker-summary" style={{left:posLeft,top:posTop,zIndex:'1040',width:'380px'}}
        className={"d-flex flex-column border rounded position-absolute bg-white marker-summary-op-" + opacity}>
        <div className="d-flex px-3 py-2 align-items-center">
            <span onMouseDown={startFavbarMove} title={t('general_clicktomove')}
                className="material-symbols-outlined cursor-pointer">open_with</span>
            <div className="d-flex px-2 gap-2 flex-grow-1 align-items-center">
                <strong className="my-auto">{time}</strong>
                <div title={marker?.user?.fullName}>
                    { marker?.user?.avatarImage
                    ? <img src={marker?.user?.avatarImage} className="border rounded-circle bg-light" alt="T.T" style={{width:'28px',height:'28px',objectFit:'contain'}}/>
                    : <span className='bg-secondary d-flex justify-content-center align-items-center text-light rounded-circle' style={{width:'28px',height:'28px'}}>
                        { marker?.user?.fullName.split(' ').map(fn => fn.charAt(0)).join('').substring(0, 2) }
                    </span> }
                </div>
                { saveStatus == OpStatus.LOADING &&
                    <Spinner title={t('general_loading_text')} animation="grow" variant="secondary" size="sm"/> }
                { saveStatus == OpStatus.SUCCESS &&
                    <div title={t('general_saved_text')} className="point-sm bg-success"></div> }
                { saveStatus == OpStatus.ERROR &&
                    <div title={t('general_errorsaving_text')} className="point-sm bg-danger"></div>}
            </div>
            <div className="d-flex gap-2">
                { loggedUser.userId === marker.userId && startEditListener != undefined &&
                <button onClick={safeStartEditListener} title={t('markersummary_editmarker_titleattr')}
                    className="btn btn-sm p-0 border-0 line-0">
                    <span className="material-icons-round cursor-pointer">edit</span>
                </button> }
                { loggedUser.userId === marker.userId && startDeleteListener != undefined &&
                <button onClick={safeStartDeleteListener} title={t('markersummary_deletemarker_titleattr')}
                    className="btn btn-sm p-0 border-0 line-0">
                    <span className="material-icons-round cursor-pointer">delete</span>
                </button> }
                <button onClick={triggerOpacity} title={t('markersummary_changetransparency_titleattr')}
                    className="btn btn-sm p-0 border-0 line-0">
                    <span className="material-icons-round cursor-pointer">contrast</span>
                </button>
                <button onClick={closeSummary} title={t('general_close')}
                    className="btn btn-sm p-0 border-0 line-0">
                    <span className="material-symbols-outlined cursor-pointer">close</span>
                </button>
            </div>
        </div>
        { opacity !== 0 && <>
            <div className="w-100 py-2 px-2 border-top">
                { editingTitle && <div className="position-relative">
                    <input ref={inputRefTitle} value={titleCopy} onChange={handleMarkerTitleChange}
                        className="form-control p-2" style={{fontSize:'1.20rem'}} placeholder={t('general_withouttitle')} />
                    <div className="d-flex gap-2 position-absolute" style={{right:'0.5em', bottom:'-1.6em'}}>
                        <div className="bg-white">
                            <button onClick={cancelTitleEdit}
                                className="btn btn-sm btn-outline-secondary line-0"
                                title={t('general_close')}>
                                <span className="material-icons-round">close</span>
                            </button>
                        </div>
                    </div>
                </div> }
                { ! editingTitle && <span onClick={enableTitleEdit} style={{fontSize:'1.20rem'}} className={(! marker.title && "text-muted") + 'text-wrap cursor-pointer  px-2'}>
                    {marker.title || t('general_withouttitle')}
                </span> }
            </div>
            <div className="d-flex flex-wrap border-bottom pt-2 px-2 gap-2 w-100">
                <div className="d-flex px-1">
                    <div onClick={() => setShowComments(false)} title={t('markersummary_editdescription')}
                        className={"mini-tab px-2 py-1 " + (! showComments && 'active')}>
                        <span className="material-icons-round">description</span>
                    </div>
                    <div onClick={() => setShowComments(true)} title={t('markersummary_comments')}
                        className={"mini-tab px-2 py-1 " + (showComments && 'active')}>
                        <span className="material-icons-round">forum</span>
                    </div>
                </div>
                { marker?.markerTags
                    ?.map(mt => <IconPill key={mt.markerTagId} iconSize='sm'
                        iconColor={mt.vtgTag.color} iconText={mt.vtgTag.icon} pillText={mt.vtgTag.name} />)}
            </div>

            { showComments && <CommentsPanel videoMarkerId={marker?.videoMarkerId}/> }

            { ! showComments && ! quickEditing &&
            <div className="hover p-3" onClick={startQuickEditing}
                title={allowEditing ? t('markersummary_clicktoedit_titleattr') : ''}
                dangerouslySetInnerHTML={{__html:marker.description || '<p class="text-muted">Sin descripción</p>'}}>
            </div> }
            { ! showComments && quickEditing &&
            <div className="position-relative">
                <CKEditor
                    editor={DecoupledEditor}
                    data={descriptionCopy}
                    config={{
                        ...configToOpenLinks, fontSize: { options:[9,11,13,'default',17,19,21] }, language: lang,
                        toolbar: ['bold', 'italic', 'link','fontFamily', 'fontColor', 'fontSize', 'bulletedList', 'undo', 'redo']
                    }}
                    onReady={edt => {
                        edt.focus();
                        edt.setData(descriptionCopy);
                        edt.ui.getEditableElement().parentElement.insertBefore(
                            edt.ui.view.toolbar.element,
                            edt.ui.getEditableElement()
                        );
                    }}
                    onChange={(_, edt) => {
                        const data = edt.getData();
                        handleMarkerDescriptionChange(data);
                    }}/>
                <div className="d-flex gap-1 position-absolute" style={{bottom:'-1.0rem',right:'0.8rem'}}>
                    <div className="bg-white rounded">
                        <button onClick={cancelQuickEditing} className="btn btn-sm btn-outline-secondary line-0" title={t('general_close')}>
                            <span className="material-icons-round">close</span>
                        </button>
                    </div>
                </div>
            </div> }
        </>}
    </div>
}

const OpStatus = {
    INIT: 0,
    LOADING: 1,
    SUCCESS: 2,
    ERROR: 3
}

export { MarkerSummary }
