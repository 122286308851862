import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function InpuTagSeparator({
    placeholder = null,
    className = '',
    classNameForInput = '',
    separator = /,|;|:/,
    textInput = '', setTextInput,
    items = [], setItems,
    autoCompleteItems = [],
    extraOnChange = search => { console.log('Chaging...', search) },
    regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
}) {

    let baseIdGenerator = Math.random() * 100;
    const { t } = useTranslation();

    const handleInputChange = (evt) => {
        const val = evt.target.value;
        setTextInput(val);
        extraOnChange(val);
        if (val.match(separator)) {
            const newItems = val.split(separator)
              .map(item => item.trim())
              .filter(item => item !== '')
              .map(item => buildNewItem(item));
            setItems([...items, ...newItems]);
            setTextInput('');
        }
    }

    const handleInputBlur = (evt) => {
        if (autoCompleteItems.length > 0) {
            console.log('Ignoring on-blur...');
            return;
        }
        const baseTxt = evt.target.value.trim();
        if (baseTxt.length == 0) {
          console.log('Empty input value');
          return;
        }
        const newItem = buildNewItem(baseTxt);
        setItems([...items, newItem]);
        setTextInput('');
        extraOnChange('');
    }

    const buildNewItem = (label, ignoreRegexp = false) => {
        if (! regexp || ignoreRegexp) {
            return {id: baseIdGenerator++, valid: true, label};
        }
        return {id: baseIdGenerator++, valid: regexp?.test(label), label};
    }

    const removeItem = (itemId) => {
        const newItems = items.filter(it => it.id !== itemId);
        setItems(newItems);
    }

    const selectItem = (item) => {
        if (item.userId) {
            const newUserItem = buildNewItem(item.title, true);
            newUserItem.userId = item.userId;
            setItems([...items, newUserItem]);
        }
        if (item.groupId) {
            const newGroupItem = buildNewItem(item.title, true);
            newGroupItem.groupId = item.groupId;
            setItems([...items, newGroupItem]);
        }
        setTextInput('');
    }

    return (
        <div className={"dropdown " + className} id="tag-sep" name="tag-sep">
            <div className={"border rounded py-1 px-2"}>
                <input id="textInput" name="textInput" type="text" data-toggle="dropdown"
                    className={"without-border d-block w-100 px-1 mb-1 " + classNameForInput}
                    placeholder={placeholder ? placeholder : t('inputtag_placeholder_commaseparated')}
                    value={textInput} onChange={handleInputChange} onBlur={handleInputBlur}/>
                <div className="dropdown-menu" id="tag-menu" name="tag-menu"
                    style={{maxHeight:'240px', overflowY:'scroll', marginLeft:'35%'}}>
                    { autoCompleteItems
                        .filter(item => item.title)
                        .map(item =>
                            <div key={item.isUser ? `usr-${item.userId}` : `grp-${item.groupId}`}
                                onClick={() => selectItem(item)}
                                className="dropdown-item d-flex justify-content-start" style={{width:'260px'}}>
                                <div className="nab-circle-btn mx-0" style={{flexShrink:'0'}}>
                                    <span className="material-icons-round">{item.isUser ? 'person' : 'groups'}</span>
                                </div>
                                <div className="mx-2 my-auto">
                                    <span style={{maxWidth:'180px'}} className="d-block small font-weight-bold">
                                        {item.title}
                                    </span>
                                    { item.subTitle &&
                                    <span style={{maxWidth:'180px'}} className="d-block small text-truncate">
                                        {item.subTitle}
                                    </span> }
                                </div>
                            </div> )}
                    { autoCompleteItems.length === 0 &&
                        <div className="dropdown-item d-flex justify-content-start disabled">
                            {t('general_noelements')}
                        </div> }
                </div>
                <div className="w-100 d-flex flex-wrap">
                    { items.map(item =>
                    <span key={item.id} title={item.valid ? '' : t('inputtag_feedback_errorformat')}
                        style={{cursor:'default'}} className={"px-1 border m-1 rounded-pill d-flex bg-light " + (! item.valid ? 'text-danger' : '')}>
                        <span className="d-inline ml-1 material-icons-round">{item.groupId ? 'groups' : 'person'}</span>                        
                        <span className="d-inline my-auto mx-1">{item.label}</span>
                        <div className="d-inline-flex cursor-pointer" onClick={() => removeItem(item.id)}>
                            <span className="material-icons-round">cancel</span>
                        </div>
                    </span> )}
                </div>
            </div> 
        </div>);
}

export { InpuTagSeparator };