import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { config } from "../config";
import { ConfirmModal } from "../general/ConfirmModal";
import { EmptyPanel } from "../general/EmptyPanel";
import { GroupItem } from "../general/GroupItem";
import { LoadingPanel } from "../general/LoadingPanel";
import { PaginationControl } from "../general/PaginationControl";
import { ModalManageGroup } from "../modals/ModalManageGroup";
import { getLoggedUser } from "../services/AuthorizarionService";
import { getPermissionsByUser } from "../services/CoreService";
import { cloneGroup, leaveGroup, listAllGroups, softDeleteGroup } from "../services/VitagGroupsService";
import { getPreferenceAsBool, getPreferenceAsNumber, PREFERENCES, savePreference }
    from "../utils/preferences/user-preferences";
import { HomeBase } from "./HomeBase";

function GroupsHome() {

    const [myGroups, setMyGroups] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(
        getPreferenceAsNumber(PREFERENCES.PAGE_SIZE)
    );
    const [currentGroup, setCurrentGroup] = useState();
    const [cloneName, setCloneName] = useState('');
    const [showModalManageGroup, setShowModalManageGroup] = useState(false);
    const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmCloneModal, setShowConfirmCloneModal] = useState(false);
    const [viewAsGrid, setViewAsGrid] = useState(
        getPreferenceAsBool(PREFERENCES.VIEW_AS_GRID)
    );
    const [needUpdateListener, setNeedUpdateListener] = useState();
    const [loading, setLoading] = useState(true);
    const [loggedUser, setLoggedUser] = useState();
    const [admPermissions, setAdmPermissions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_groups_home');
        const user = getLoggedUser();
        setLoggedUser(user);
        getPermissionsByUser()
            .then(ps => setAdmPermissions(ps.data))
            .catch(err => console.error('Error getting permissions', err));
    }, []);

    const getAllGroups = (search) => {
        setLoading(true);
        listAllGroups(search, currentPage, pageSize)
            .then(resp => {
                const total = resp.headers['x-count-total'];
                setTotalCount(total);
                setMyGroups(resp.data);
                setTimeout(setLoading, 600, false);
            })
            .catch(err => {
                console.error('Error getting my groups...', err);
                setLoading(false);
            });
    }

    const deleteGroup = () => {
        const deletingGroup = {...currentGroup};
        softDeleteGroup(deletingGroup.groupId)
            .then(resp => setNeedUpdateListener(resp))
            .catch(err => console.error('Error deleting tag :(', err));
    }

    const leaveCurrentGroup = () => {
        const deletingGroup = {...currentGroup};
        leaveGroup(deletingGroup.groupId)
            .then(resp => setNeedUpdateListener(resp))
            .catch(err => console.error('Error deleting tag :(', err));
    }

    const copyGroup = () => {
        let newName = cloneName;
        if (! newName || newName.trim().length === 0) {
            newName = `${currentGroup.name} ${t('page_videos_newtitlecopy')}`;
        }
        cloneGroup(currentGroup.groupId, newName)
            .then(resp => setNeedUpdateListener(resp))
            .catch(err => console.error('Error cloning tag :(', err));
    }

    const startNewGroup = () => {
        setCurrentGroup(null);
        setShowModalManageGroup(true);
    };

    const startGroupEditing = (grp) => {
        setCurrentGroup(grp);
        setShowModalManageGroup(true);
    }

    const confirmDeleteGroup = (grp) => {
        setCurrentGroup(grp);
        setShowConfirmDeleteModal(true);
    }

    const confirmLeaveGroup = (grp) => {
        setCurrentGroup(grp);
        setShowConfirmLeaveModal(true);
    }

    const confirmCloneGroup = (grp) => {
        setCurrentGroup(grp);
        setCloneName(grp.name + t('modal_groups_clonegroup_clonesuffix'));
        setShowConfirmCloneModal(true);
    }

    const triggerGridListView = () => {
        savePreference(PREFERENCES.VIEW_AS_GRID, ! viewAsGrid);
        setViewAsGrid(! viewAsGrid);
    }

    return (
        <React.Fragment>
        <HomeBase
            searchListener={getAllGroups}
            resetListener={() => setCurrentPage(0)}
            extraButtons={[
                {btnIcon: viewAsGrid ? 'view_list' : 'grid_view', btnClass: 'btn text-muted border-none', btnClick: triggerGridListView},
                {btnText: t('page_groups_button_creategroup'), btnClass: 'btn btn-primary bg-vitag', btnClick: startNewGroup}
            ]}
            currentPage={currentPage}
            pageSize={pageSize}
            forceRefresh={needUpdateListener}
            currentTabName="groups">
                { loading &&
                    <LoadingPanel/>
                }

                { ! loading && myGroups.length === 0 &&
                    <EmptyPanel message={t('page_groups_message_withoutgroupstoshow')}/>
                }

                { ! loading && myGroups.length > 0 &&
                    <Row className="mt-4 home-container">
                        { myGroups.map(grp => 
                            <GroupItem
                                key={grp.groupId}
                                leGroup={grp}
                                loggedUserId={loggedUser?.userId}
                                viewAsGrid={viewAsGrid}
                                startGroupEditing={startGroupEditing}
                                confirmCloneGroup={confirmCloneGroup}
                                confirmLeaveGroup={confirmLeaveGroup}
                                confirmDeleteGroup={confirmDeleteGroup} />
                        )}
                    </Row>
                }

                { totalCount > 0 &&
                    <PaginationControl
                        totalCount={totalCount}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}/>
                }

        </HomeBase>

        <ConfirmModal
            modalTitle={ t('modal_groups_deletegroup_title') }
            modalText={ t('modal_groups_deletegroup_text', {groupname:currentGroup?.name})}
            acceptCallback={deleteGroup}
            showModal={showConfirmDeleteModal}
            setShowModal={setShowConfirmDeleteModal}/>

        <ConfirmModal
            modalTitle={ t('modal_groups_leavegroup_title') }
            modalText={ t('modal_groups_leavegroup_text', {groupname:currentGroup?.name})}
            acceptCallback={leaveCurrentGroup}
            showModal={showConfirmLeaveModal}
            setShowModal={setShowConfirmLeaveModal}/>

        <ConfirmModal
            modalTitle={ t('modal_groups_clonegroup_title') }
            modalText={ t('modal_groups_clonegroup_text', {groupname:currentGroup?.name})}
            acceptCallback={copyGroup}
            showModal={showConfirmCloneModal}
            setShowModal={setShowConfirmCloneModal}>
            <Form.Group as={Row} controlId="cloneName" className="d-flex m-0">
                <Form.Label className="text-wrap" column sm={4}>
                    { t('modal_groups_clonegroup_label_newname') }
                </Form.Label>
                <Col sm={8} className="my-auto pb-4">
                    <Form.Control type="text"
                        value={cloneName} onChange={(evt) => setCloneName(evt.target.value)}
                        placeholder={t('modal_groups_clonegroup_placeholder_newname', {groupname:currentGroup?.name})}/>
                </Col>
            </Form.Group>
        </ConfirmModal>

        <ModalManageGroup
            admPermissions={admPermissions}
            initialGroup={currentGroup}
            successListener={setNeedUpdateListener}
            showModal={showModalManageGroup}
            setShowModal={setShowModalManageGroup} />

        </React.Fragment>
    );
}

export { GroupsHome };