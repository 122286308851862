import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Alert, Modal } from 'react-bootstrap';
import { updateVideo } from '../services/VitagVideosService';
import { UploaderInput } from '../uploader/UploaderInput';
import { UploaderPreview } from '../uploader/UploaderPreview';
import { Typologies } from '../utils/TypologiesEnum';
import { PERMISSIONS, useCheckPermission } from '../utils/permissions/PermissionsUtils';
import { useTranslation } from 'react-i18next';
import { ListsSelector } from '../general/ListsSelector';
import { assingListsForVideo } from '../services/VitagListsService';
import {listTypologiesByInternalId} from '../services/CoreService';
import classNames from 'classnames';

function ModalEditVideo({
        leVideo, saveVideoListener, showModal, setShowModal
    } ) {

    const [vtgVideo, setVtgVideo] = useState({ title:'', url:'', miniature:'', sharingUrl:'', timeInSeconds:-1, isTrainingMaterial: false, projectionType: Typologies.PT_STANDARD});
    const [videoUrl, setVideoUrl] = useState('');
    const [videoDuration, setVideoDuration] = useState();
    const [videoProvider, setVideoProvider] = useState();
    const [videoProviderProjection, setVideoProviderProjection] = useState(false);
    const [videoThumbnail, setVideoThumbnail] = useState('');
    const [videoReady, setVideoReady] = useState(false);
    const [selectedListIds, setSelectedListIds] = useState([]);
    const { t } = useTranslation();
    const allowIsTrainingMaterial = useCheckPermission(PERMISSIONS.MARK_AS_TRAINING_MATERIAL);
    const [allProjections, setProjections] = useState([]);
    const [workWithFile, setWorkFile] = useState(false);

    const initModal = () => {
        if (leVideo) {
            setVtgVideo(leVideo);
            setVideoUrl(leVideo.url);
            setVideoProvider(leVideo.videoProvider);
            setVideoThumbnail(leVideo.miniature);
            setSelectedListIds([]);
            const duration = moment.duration(leVideo.timeInSeconds, 'seconds');
            setVideoDuration(duration.humanize());
            setVideoProjection(leVideo.videoProvider);
            listTypologiesByInternalId(Typologies.PROJECTION_TYPE)
                .then(resp => mapProjections(resp.data, true))
                .catch(err => console.error('Error getting projections', err));
        } else {
            console.error('Error editing video');
        }
        setVideoReady(true);
    }

    const mapProjections = (data, reset = false) => {
        const mappedProjections = data.map(ic => ({
            value:ic.typologyId, label:ic.description
        }));
        const sortedProjections = mappedProjections.sort((a, b) => (a.value > b.value) ? 1 : -1)
        const newProjections = reset ? sortedProjections : [...sortedProjections, ...allProjections];
        setProjections(newProjections);
    }

    const handlingProviderChange = (event) => {
        setVideoProvider(event);
        setVideoProjection(event);
    }

    const isInvalid = (value) => {
        return !value || value.trim() === '';
    };

    const setVideoProjection = (provider) => {
        if (provider.internalId === Typologies.VT_YOUTUBE || 
            provider.internalId === Typologies.VT_VIMEO || 
            provider.internalId === Typologies.VT_TWITCH || 
            provider.internalId === Typologies.VT_LIVESESSION ) 
        {
            setVideoProviderProjection(false);
        } else {
            setVideoProviderProjection(true);
        }
    }


    const saveVideoChanges = () => {
        const newVideo = {...vtgVideo, sharingUrl: '', url: videoUrl};
        if (newVideo.miniature !== videoThumbnail) {
            newVideo.miniature = videoThumbnail;
        }
        updateVideo(newVideo.hashId, newVideo)
            .then(resp => {
                console.log('Video updated successful', resp);
                saveVideoListener && saveVideoListener(resp);
                closeThisModal();
                return assingListsForVideo(vtgVideo.videoId, selectedListIds);
            })
            .then(_ => {
                console.log('List saved successful');
            })
            .catch(err => console.error('Error updating video...', err));
    }

    const handleVideoTitle = (event) => {
        const title = event.target.value;
        setVtgVideo({...vtgVideo, title});
    }

    const handleTrainingMaterialChange = (event) => {
        const inputChecked = event.target.checked;
        setVtgVideo({...vtgVideo, isTrainingMaterial: inputChecked});
    }

    const closeThisModal = () => setShowModal(false);

    const handleProjectionType = (event) => {
        const inputValue = event.target.value;
        setVtgVideo({...vtgVideo, projectionType: inputValue});
    }

    return(
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="xl">
        <Modal.Header>
            <button onClick={closeThisModal} title={t('modal_videomanagement_button_cancel')}
                type="button" className="btn btn-sm text-muted">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>{ t('modal_videomanagement_title_editvideo') }</Modal.Title>
            <button onClick={saveVideoChanges} title={t('modal_videomanagement_button_save')}
                type="button" className="btn btn-sm text-vitag"
                disabled={vtgVideo?.title?.trim() == '' || videoUrl?.trim() == ''}>
                <span className="material-icons-round">save</span>
            </button>
        </Modal.Header>
        <Modal.Body className='px-5'>
            <Row>
                <Col xs={12} lg={6}>
                    <UploaderInput
                        vtgVideo={vtgVideo}
                        setVtgVideo={setVtgVideo}
                        videoUrl={videoUrl}
                        setVideoUrl={setVideoUrl}
                        setVideoReady={setVideoReady}
                        setVideoProvider={handlingProviderChange}
                        setVideoDuration={setVideoDuration}
                        setWorkFile={setWorkFile}
                        labelInCenter={false}/>
                    
                    <div className="d-flex w-100 mt-3">
                        <label className="wrap">
                            {t('modal_videomanagement_label_selectlistsforvideo')}
                        </label>    
                    </div>
                    
                    <ListsSelector
                        vtgVideoId={vtgVideo?.videoId}
                        selectedListIds={selectedListIds}
                        setSelectedListIds={setSelectedListIds}
                        refreshList={vtgVideo?.videoId}
                        showMini={true}
                        listHeight={videoProviderProjection || workWithFile ?'300px':'190px'} />

                </Col>
                <Col xs={12} lg={6}>
                    <UploaderPreview
                        vtgVideo={vtgVideo}
                        setVtgVideo={setVtgVideo}
                        videoReady={videoReady}
                        setVideoReady={setVideoReady}
                        videoDuration={videoDuration}
                        setVideoDuration={setVideoDuration}
                        setVideoThumbnail={setVideoThumbnail}
                        originalThumbnail={videoThumbnail}
                        videoUrl={videoUrl}
                        videoProvider={videoProvider}
                        isEdit={true}/>

                    <div className="form-group mt-4">
                        <label htmlFor="inputVideoTitle">
                            {t('modal_videomanagement_label_title')}
                        </label>
                        <input value={vtgVideo?.title} onChange={handleVideoTitle}
                            placeholder={t('modal_videomanagement_placeholder_title')}
                            type="text" 
                            className={classNames('form-control', { 'invalid': isInvalid(vtgVideo.title) })}
                            id="inputVideoTitle"/>
                    </div>

                    { allowIsTrainingMaterial &&
                    <div className="w-100 d-flex justify-content-end mb-3">
                        <div className="form-check">
                            <input checked={vtgVideo?.isTrainingMaterial} onChange={handleTrainingMaterialChange}
                                className="form-check-input" type="checkbox" id="materialTraining"/>
                            <label className="form-check-label cursor-pointer" htmlFor="materialTraining">
                                {t('modal_videomanagement_label_istrainingmaterial')}
                            </label>
                        </div>
                    </div> }

                    { (videoProviderProjection || workWithFile) &&
                        <div className="w-100 mb-3">
                            <label htmlFor="inputProjectionOption">
                                { t('modal_videomanagement_label_projection_type') }
                            </label>
                            <select className="custom-select"  value={String(vtgVideo?.projectionType)}
                                    id="inputProjectionOption" onChange={handleProjectionType}>
                                    { allProjections.map(st => 
                                    <option key={st.value} value={String(st.value)}>
                                        {t(st.label)}
                                    </option>) }
                            </select>
                        </div>}
                </Col>
            </Row>

        </Modal.Body>
    </Modal>)
}

export { ModalEditVideo };