import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Alert, Collapse } from 'react-bootstrap';
import Papa from 'papaparse';
import { Trans, useTranslation } from 'react-i18next';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { IconIndicator } from '../general/IconIndicator';
import { importListOfTags } from '../services/VitagTagsService';

function ModalImportTags({
    showModal, setShowModal,
    successImportListener = () => console.log('Import tags successful')
} ) {

    const [toImport, setToImport] = useState([]);
    const [fileName, setFileName] = useState('');
    const [importSummary, setImportSummary] = useState();
    const { addToast } = useCustomToast();
    const { t } = useTranslation();

    const COLUMNS = {
        PARENT:0, NAME:1, COLOR:2, ICON:3
    };

    const initModal = () => {
        setImportSummary(null);
        setFileName('');
        setToImport([]);
    }

    const closeThisModal = () => {
        setShowModal(false);
    }

    const startImportTags = () => {
        importListOfTags(toImport)
            .then(_ => {
                closeThisModal();
                successImportListener();
                addToast({
                    body: t('modal_importtags_toast_success_text'),
                    variant: 'success'
                })
            })
            .catch(err => console.error('Error', err));
    }


    const startFileSelection = () => {
        document.getElementById('import-tags-input').click();
    }

    const handleFileSelection = (event) => {
        var file = event.target.files[0];
        setFileName(file.name);
        Papa.parse(file, { complete:handleParseResult });
    }

    const handleParseResult = (result) => {
        const newSummary = {
            errors: result.errors.length,
            rows: result.data.length - 1
        };
        setImportSummary(newSummary);
        const rows = result.data.slice(1) //Ignoring first row
            .filter(rowData => rowData.length > 1) // Ignoring blank rows
            .map(row => ({
                parent: row[COLUMNS.PARENT],
                name: row[COLUMNS.NAME],
                color: row[COLUMNS.COLOR],
                icon: row[COLUMNS.ICON],
                subTags: []
            }));
        const parentTags = new Map(
            rows.filter(tag => ! tag.parent)
                .map(tag => [tag.name, tag])
        );
        rows.filter(tag => tag.parent)
            .forEach(child => {
                const parent = parentTags.get(child.parent);
                parent.subTags.push(child);
                parentTags.set(parent.name, parent);
            });
        // console.log(parentTags);
        const arrayTags = Array.from(parentTags.values());
        console.log(arrayTags);
        setToImport(arrayTags);
    }

    return(<>
        <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg'>
            <Modal.Header>
                <div className="d-flex justify-content-between w-100">
                    <button onClick={closeThisModal} className="btn btn-sm text-secondary"
                        title={t('general_cancel')}>
                        <span className="material-icons-round">close</span>
                    </button>
                    <Modal.Title>{t('modal_importtags_title')}</Modal.Title>
                    <button onClick={startImportTags} className="btn btn-sm text-vitag"
                        title={t('modal_importtags_upload_titleattr')}>
                        <span className="material-icons-round">upload</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className='px-5 pb-4 pt-3'>
                <Row>
                    <Col sm={12}>
                        <p>
                            <Trans i18nKey="modal_importtags_text">
                                Realiza la carga de múltiples etiquetas por medio
                                de un archivo CSV con un formato especial.
                                Puedes descargar una plantilla de ejemplo en
                                <a className='vitag-text' download='plantilla-botoneras.csv' href='/assets/botoneras.csv'>este enlace</a>.
                            </Trans>
                        </p>
                        <div className="input-group mb-3">
                            <input onClick={startFileSelection} value={fileName}
                                type="text" readOnly className="form-control"
                                placeholder={t('modal_importtags_placeholder_selectfile')}/>
                            <div className="input-group-append">
                                <button onClick={startFileSelection} className="btn btn-outline-secondary">
                                    {t('modal_importtags_label_selectfile')}
                                </button>
                            </div>
                        </div>
                        { importSummary?.errors > 0 &&
                        <Alert variant='danger'>
                            {t('modal_importtags_alert', {errors:importSummary?.errors})}
                        </Alert>}
                    </Col>
                    <Col sm={12} style={{overflowY:'scroll',maxHeight:'360px'}}>
                        <Row className='p-2 border-bottom'>
                            <Col xs={6}>
                                <span className='fw-bold'>{t('modal_importtags_title_th')}</span>
                            </Col>
                            <Col xs={4}>
                                <span className='fw-bold'>{t('modal_importtags_color_th')}</span>
                            </Col>
                            <Col xs={2}>
                                <span className='fw-bold'>{t('modal_importtags_icon_th')}</span>
                            </Col>
                        </Row>
                        { toImport.map((res) => <ImportTagItem key={res.name} parentTag={res}/>)}
                    </Col>
                </Row>
                <input id='import-tags-input' onChange={handleFileSelection} type='file' className='d-none' accept='.csv'/>
            </Modal.Body>
        </Modal>
    </>)
}

function ImportTagItem({ parentTag }) {

    const [collapsed, setCollapsed] = useState(false);

    return <>
        <Row className='p-2 border-bottom align-items-center bg-light'>
            <Col xs={6} className='d-flex gap-1 align-items-center'>
                <span onClick={() => setCollapsed(! collapsed)}
                    className="material-icons-round cursor-pointer">
                    expand_more
                </span>
                <span onClick={() => setCollapsed(! collapsed)}
                    className='ml-2'>
                    {parentTag.name} ({parentTag.subTags.length})
                </span>
            </Col>
            <Col xs={4} className='d-flex gap-1 align-items-center'>
                <div className="color-preview" style={{backgroundColor:parentTag.color}}>
                </div>
                {parentTag.color}
            </Col>
            <Col xs={2}>
                <IconIndicator
                    iconColor={parentTag.color}
                    iconName={parentTag.name}
                    iconText={parentTag.icon} />
            </Col>
        </Row>
        <Collapse in={collapsed}>
            <div>
                { (parentTag.subTags || []).map((st, idx) =>
                    <Row key={parentTag.name + '-' + st.name + '-' + idx}
                        className='px-2 py-1 border-bottom align-items-center'>
                        <Col xs={6} className='d-flex gap-1 align-items-center'>
                            <span className='ml-5'>{st.name}</span>
                        </Col>
                        <Col xs={4} className='d-flex gap-1 align-items-center'>
                            <div className="color-preview" style={{backgroundColor:st.color}}>
                            </div>
                            {st.color}
                        </Col>
                        <Col xs={2}>
                            <IconIndicator
                                iconColor={st.color}
                                iconName={st.name}
                                iconText={st.icon} />
                        </Col>
                    </Row>
                )}
            </div>
        </Collapse>
    </>

}

export { ModalImportTags };