import { useEffect, useState } from "react"
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { config } from "../config";
import SyntaxHighlighter from "react-syntax-highlighter";

function TestAPIPanel({
    panelTitle="Petición de prueba",
    contentType='application/json',
    initialParams=[],
    urlComplement=''
}) {

    const [apiUrl, setApiUrl] = useState('');
    const [authHeader, setAuthHeader] = useState('');
    const [requestParams, setRequestParams] = useState([]);
    const [apiResponse, setApiResponse] = useState('');
    const [apiMethod, setApiMethod] = useState('GET');
    const { addToast } = useCustomToast();

    useEffect(() => {
        setRequestParams(initialParams);
        setApiUrl(config.CORE_DOMAIN + urlComplement);
        setAuthHeader("Bearer coloca-tu-token-aqui");
    }, [])
    
    const handleParamValueChange = (key, evt) => {
        const newValue = evt.target.value;
        const paramIdx = requestParams.findIndex(param => param.key == key);
        const param = requestParams[paramIdx];
        param.value = newValue;
        const newParams = [...requestParams];
        requestParams[paramIdx] = param;
        setRequestParams(newParams);
    }

    const makeRequest = () => {
        if(! apiUrl.startsWith(config.CORE_DOMAIN)) {
            addToast({
                variant:'error',header:'URL incorrecta',
                body:"Debes usar una URL de ViTAG válida"
            });
            return;
        }
        if(! authHeader.startsWith('Bearer')) {
            addToast({
                variant:'error',header:'Header incorrecto',
                body:"Revisa el valor del header 'Authorization', debe empezar con 'Bearer '"
            });
            return;
        }
        const params = {};
        requestParams.forEach(param => params[param.key]=param.value);
        fetch(apiUrl + '?' + new URLSearchParams(params), {
            method:apiMethod,
            headers:{Authorization:authHeader,'Content-type':contentType}
        })
        .then(resp => contentType == 'application/json' ? resp.json() : resp.text())
        .then(content => {
            console.log('API test response', content);
            if (contentType == 'application/json') {
                setApiResponse(JSON.stringify(content, null, 2));
            } else {
                setApiResponse(content);
            }
        })
        .catch(err => {
            console.error('Error making request', err);
        });
    }

    return <>
        <h4>{panelTitle}</h4>
        <div className="d-flex flex-column">
            <div className="form-group">
                <label className="fw-bold">URL</label>
                <div className="input-group">
                    <select value={apiMethod} onChange={evt => setApiMethod(evt.target.value)}
                        className="custom-select" style={{width:'160px',flex:'none',backgroundColor:'#e9ecef'}}>
                        <option value="GET" selected>GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="DELETE">DELETE</option>
                    </select>
                    <input value={apiUrl} onChange={evt => setApiUrl(evt.target.value)}
                        type="text" className="form-control"/>
                </div>
            </div>
            <div className="form-group">
                <label className="fw-bold">Headers</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width:'160px'}}>Authorization</span>
                    </div>
                    <input value={authHeader} onChange={evt => setAuthHeader(evt.target.value)}
                        type="text" className="form-control"/>
                </div>
            </div>
            { requestParams.length > 0 &&
            <div className="form-group">
                <label className="fw-bold">Parámetros</label>
                { requestParams.map(param => 
                <div key={param.key} className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" style={{width:'160px'}}>
                            <span className="small">
                                {param.label} ({param.optional ? 'opcional':'obligatorio'})
                            </span>
                        </span>
                    </div>
                    <input value={param.value} onChange={(evt) => handleParamValueChange(param.key, evt)}
                        type="text" className="form-control"/>
                </div> )}
            </div> }
            <div className="form-group">
                <label className="fw-bold">Respuesta</label>
                { contentType == 'application/json' ?
                <SyntaxHighlighter language="json" customStyle={{maxHeight:'240px',minHeight:'160px',maxWidth:'800px'}}>
                    {apiResponse}
                </SyntaxHighlighter> :
                <textarea value={apiResponse} readOnly name="test-response" id="test-response"
                    style={{whiteSpace:'pre',overflowWrap:'normal',overflowX:'scroll'}}
                    rows="8" className="form-control form-control-sm text-monospace">
                </textarea>}
            </div>
            <button onClick={makeRequest} className="btn btn-sm btn-secondary align-self-end">
                Enviar petición
            </button>
        </div>
    </>
}

export { TestAPIPanel }
