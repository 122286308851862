import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { setupInterceptors } from "./comms/http"

function InjectAxiosInterceptors () {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    console.log('This effect (setup interceptors) is called once');
    setupInterceptors(history, location);
  }, [history]);

  // not rendering anything
  return null;
}

export { InjectAxiosInterceptors };
