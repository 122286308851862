import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { HelpButton } from "../general/HelpButton";

function TabControl({ activeTab, children }) {

    const history = useHistory();
    const { t } = useTranslation();

    const handleTabClick = (clickedTab) => {
        if (clickedTab === activeTab) {
            console.log('Quiet, not move...');
            return;
        }
        switch(clickedTab) {
            case 'videos':
                history.push('/videos');
                break;
            case 'groups':
                history.push('/groups');
                break;
            case 'tags':
                history.push('/tags');
                break;
            case 'profile':
                history.push('/me');
                break;
            default:
                console.error('Invalid tab page');
        }
    }

    return (
        <React.Fragment>
            {children}
            <ul className="view-movile d-flex d-md-none p-0 gap-0 profile-tab-items border-bottom" style={{marginTop:'82px'}}>
                <li id="videos" onClick={() => handleTabClick('videos')}
                    className={activeTab === 'videos' ? 'active': ''}>{t('tab_videos')}</li>
                <li id="tags" onClick={() => handleTabClick('tags')}
                    className={activeTab === 'tags' ? 'active': ''}>{t('tab_tags')}</li>
                <li id="groups" onClick={() => handleTabClick('groups')}
                    className={activeTab === 'groups' ? 'active': ''}>{t('tab_groups')}</li>
                <li id="profile" onClick={() => handleTabClick('profile')}
                    className={activeTab === 'profile' ? 'active': ''}>{t('tab_profile')}</li>
                <li className="ml-auto">
                    <HelpButton/>
                </li>
            </ul>
            <ul className="view-desktop d-none d-md-flex gap-3 profile-tab-items border-bottom" style={{marginTop:'82px'}}>
                <li id="videos" onClick={() => handleTabClick('videos')}
                    className={activeTab === 'videos' ? 'active': ''}>{t('tab_videos')}</li>
                <li id="tags" onClick={() => handleTabClick('tags')}
                    className={activeTab === 'tags' ? 'active': ''}>{t('tab_tags')}</li>
                <li id="groups" onClick={() => handleTabClick('groups')}
                    className={activeTab === 'groups' ? 'active': ''}>{t('tab_groups')}</li>
                <li id="profile" onClick={() => handleTabClick('profile')}
                    className={activeTab === 'profile' ? 'active': ''}>{t('tab_profile')}</li>
                <li className="ml-auto mr-5">
                    <HelpButton/>
                </li>
            </ul>
        </React.Fragment>
    )
}

export { TabControl };
