import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Papa from 'papaparse';
import { ONLY_EDIT } from "../utils/ShareContentUtils";
import { IconIndicator } from "./IconIndicator";
import { saveAs } from "file-saver";
import { markFavoriteTag, removeFavoriteTag } from "../services/VitagTagsService";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faEmptyStar }  from '@fortawesome/free-regular-svg-icons';
import { faStar as faFillStar }  from '@fortawesome/free-solid-svg-icons';

function TagItem({ leTag, loggedUserId, viewAsGrid, startEditTag, startSharedListTag, startShareTag, confirmCloneTag, confirmDeleteTag, dismissTag, viewTagDetail }) {

    const { t } = useTranslation();
    const [currentTag, setCurrentTag] = useState(leTag);

    useEffect(() => setCurrentTag(leTag), [leTag]);

    const handcurrentTagClick = (evt) => {
        if (evt.shiftKey && evt.altKey) {
            alert(`Tag ID de '${currentTag.name}' = ${currentTag.tagId}`);
            return;
        }
        if (currentTag.userId === loggedUserId || currentTag.shareType === ONLY_EDIT) {
            startEditTag(currentTag);
            return;
        }
        viewTagDetail(currentTag);
    }

    const  handleFavoriteTrigger = () => {
        if (! currentTag.isFav) {
            markFavoriteTag(currentTag.tagId)
                .then(_ => setCurrentTag({...currentTag, isFav: true}))
                .catch(err => console.error('Error saving fav', err));
            return;
        }
        removeFavoriteTag(currentTag.tagId)
            .then(_ => setCurrentTag({...currentTag, isFav: false}))
            .catch(err => console.error('Error quiting fav', err));        
    }

    const exportTag = () => {
        const tagAsCsv = [['parent','tag','color','icon']];
        tagAsCsv.push(['', currentTag.name, currentTag.color, currentTag.icon]);
        (currentTag.subTags || []).forEach(stag => {
            tagAsCsv.push([currentTag.name, stag.name, stag.color, stag.icon]);
        });
        const csv = Papa.unparse(tagAsCsv);
        const blobCsv = new Blob([csv], {type:'text/csv;charset=utf-8'});
        const fileName = `${t('general_tagscsvfilename')}-${currentTag.name}.csv`;
        saveAs(blobCsv, fileName);
    }

    const tagOptionsDropdown = (
        <div className="dropdown">
            <div className="nab-circle-btn" data-toggle="dropdown">
                <span className="material-icons-round">
                    more_vert
                </span>
            </div>
            <div className="dropdown-menu dropdown-menu-left">
                <button onClick={() => viewTagDetail(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_showtag') }
                </button>
                { (loggedUserId === currentTag.userId || currentTag.shareType === ONLY_EDIT) &&
                <button onClick={() => startEditTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_edit') }
                </button>}
                { loggedUserId === currentTag.userId &&
                <button onClick={() => startShareTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_share') }
                </button>}
                { loggedUserId === currentTag.userId &&
                <button onClick={() => startSharedListTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_sharedlist') }
                </button>}
                <button onClick={exportTag} className="dropdown-item">
                    { t('page_tags_tagitem_option_exporttag') }
                </button>
                { (loggedUserId === currentTag.userId || currentTag.shareType === ONLY_EDIT) &&
                <button onClick={() => confirmCloneTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_clone') }
                </button>}
                { loggedUserId === currentTag.userId &&
                <button onClick={() => confirmDeleteTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_delete') }
                </button>}
                { (loggedUserId !== currentTag.userId || currentTag.isTrainingMaterial) &&
                <button onClick={() => dismissTag(currentTag)} className="dropdown-item">
                    { t('page_tags_tagitem_option_dismiss') }
                </button>}
            </div>
        </div> );

    const tagExtraIndicators = (
        <div className="d-flex gap-2">
            { loggedUserId !== currentTag.userId && ! currentTag.isTrainingMaterial &&
            <div title={ t('generalitem_sharedwithme_title') } className="circle-mini-badge bg-white">
                <span className="material-icons-round text-dark">share</span>
            </div> }
            { currentTag.isTrainingMaterial &&
            <div title={t('generalitem_trainingmaterial_title')} className="circle-mini-badge bg-white">
                <span className="material-icons-round text-dark">school</span>
            </div> }
        </div> );

    const favoriteIndicator = (
        <span onClick={handleFavoriteTrigger}
            title={t(! currentTag.isFav ? 'page_tags_tagitem_label_markasfavorite' : 'page_tags_tagitem_label_removefavorite')}
            className={(currentTag.isFav ? 'text-warning' : 'text-secondary') + ' cursor-pointer'}>
            <FontAwesomeIcon icon={currentTag.isFav ? faFillStar : faEmptyStar} style={{fontSize:'1.2em'}}/>
        </span>
    );

    return (
        viewAsGrid ?
        /* grid view */
        <Col xs={12} sm={6} md={4} lg={3}>
            <div className="mb-4">
                <div className="position-relative bg-light rounded border py-4">
                    <IconIndicator iconSize="lg" className="mx-auto cursor-pointer" iconColor={currentTag.color} iconText={currentTag.icon} iconOnClick={handcurrentTagClick}/>
                    { (loggedUserId === currentTag.userId || currentTag.shareType === ONLY_EDIT || currentTag.isTrainingMaterial) &&
                    <div className="corner-tl">
                        { tagOptionsDropdown }
                    </div> }
                    <div className="corner-br">
                        { tagExtraIndicators }
                    </div>
                    <div className="corner-tr">
                        { favoriteIndicator }
                    </div>
                </div>
                <div className="px-1 pt-1 rounded-bottom cursor-pointer" onClick={handcurrentTagClick}>
                    <p className="mb-0 font-weight-bold text-truncate" title={currentTag.name}>{currentTag.name}</p>
                    <p className="mb-0 small mb-2 text-muted">
                        {currentTag.subTags?.length} { t('page_tags_tagitem_tagcounter')}
                    </p>
                </div>
            </div>
        </Col> : 
        /* list view */
        <Col xs={12} sm={6}>
            <div className="mb-4 d-flex">
                <div className="bg-light d-flex position-relative border rounded px-5 py-3 mr-3">
                    <IconIndicator iconSize="lg" className="mx-4 cursor-pointer" iconColor={currentTag.color} iconText={currentTag.icon} iconOnClick={handcurrentTagClick}/>
                    { (loggedUserId === currentTag.userId || currentTag.shareType === ONLY_EDIT || currentTag.isTrainingMaterial) &&
                    <div className="corner-tl">
                        { tagOptionsDropdown }
                    </div> }
                    <div className="corner-br">
                        { tagExtraIndicators }
                    </div>
                    <div className="corner-tr">
                        { favoriteIndicator }
                    </div>
                </div>
                <div className="cursor-pointer my-auto" onClick={handcurrentTagClick}>
                    <p className="mb-0 font-weight-bold text-truncate" title={currentTag.name}>{currentTag.name}</p>
                    <p className="mb-0 small mb-2 text-muted">
                        {currentTag.subTags?.length} { t('page_tags_tagitem_tagcounter')}
                    </p>
                </div>
            </div>
        </Col>
    );
}

export { TagItem }