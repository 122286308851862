import { useEffect } from "react";
import { loadUserPreferences } from "./user-preferences";

function InjectUserPreferences () {

    useEffect(() => {
        console.log('This effect (loading preferences) is called once');
        loadUserPreferences();
    }, []);

    return null;
}

export { InjectUserPreferences };
