import axios from 'axios';

function listVideos(
    searching, tags = [], lists = [], start = 0, max = 50,
    columnOrder = 'date', asc = false, onlyDeleted = false, includeShareType = true
) {
    start = start * max; // for pagination
    const params = {
        searching, tags, lists,
        start, max, columnOrder, asc,
        includes:includeShareType,
        recyclerBin:onlyDeleted
    }
    return axios.get('/vtg-videos', { params });
}

function listDeletedVideos(
    searching, start = 0, max = 50, columnOrder = 'date', asc = false
) {
    start = start * max; // for pagination
    const params = {
        searching, start, max, columnOrder, asc,
        includes:false, recyclerBin:true
    }
    return axios.get('/vtg-videos', { params });
}


function getVideoDetail(hashId) {
    return axios.get(`/vtg-videos/${hashId}`);
}

function createVideo(newVtgVideo) {
    return axios.post('/vtg-videos', newVtgVideo);
}

function deleteVideo(hashId, sharedDelete) {
    return axios.delete(`/vtg-videos/${hashId}`, { params: { sharedDelete: sharedDelete } });
}

function updateVideo(hashId, vtgVideo) {
    return axios.put(`/vtg-videos/${hashId}`, vtgVideo);
}

function cloneVideo(hashId, cloneRequest) {
    return axios.post(`/vtg-videos/clone/${hashId}`, cloneRequest);
}

function ignoreVideo(hashId) {
    return axios.delete(`/vtg-videos/ignore/${hashId}`);
}

function deleteOrDismissVideos(ownVideos, sharedVideos, sharedDelete) {
    const params = new URLSearchParams();
    if (ownVideos && ownVideos.length > 0) {
        ownVideos.forEach(id => params.append('ownVideos', id));
    }
    if (sharedVideos && sharedVideos.length > 0) {
        sharedVideos.forEach(id => params.append('sharedVideos', id));
    }
    if (sharedDelete) {
        params.append('sharedDelete', sharedDelete);
    }
    return axios.delete(`/vtg-videos/all?${params.toString()}`);
}

function restoreTrainingVideos() {
    return axios.put('/vtg-videos/restore-all-training-material/', {});
}

function markFavoriteVideo(hashId) {
    return axios.put(`/vtg-videos/fav/${hashId}`, {});
}

function quitFavoriteVideo(hashId) {
    return axios.delete(`/vtg-videos/fav/${hashId}`);
}

function shareVideo(hashId, shareRequest) {
    return axios.post(`/vtg-videos/share/${hashId}`, shareRequest);
}

function shareVideoByLink(hashId, shareRequest) {
    return axios.post(`/vtg-videos/share/by-link/${hashId}`, shareRequest);
}

function canAutoShareVideo(hashId) {
    return axios.get(`/vtg-videos/auto-share/${hashId}`);
}

function saveAutoShareVideo(hashId) {
    return axios.post(`/vtg-videos/auto-share/${hashId}`, {});
}

function shareVideos(shareRequest) {
    return axios.post('/vtg-videos/share-all/', shareRequest);
}

function importVideos(importVideoRequests) {
    return axios.post('/vtg-videos/import/', importVideoRequests);
}

function usersInVideo(hashId) {
    return axios.get(`/vtg-videos/users/${hashId}`);
}

function changeVideoTitle(hashId, newTitle) {
    const changeTitleRequest = { hashId, newTitle };
    return axios.put('/vtg-videos/change-title', changeTitleRequest);
}

function saveLastPlaying(hashId, lastPlaying) {
    return axios.put(`/vtg-videos/last/${hashId}`, {}, { params: { last:lastPlaying } });
}

function restoreVideo(hashId) {
    return axios.put(`/vtg-videos/restore/${hashId}`, {});
}

function restoreVideos(hashIds) {
    return axios.put('/vtg-videos/restore-all/', {}, { params: {hashes:hashIds} });
}

export { 
    listVideos, listDeletedVideos, getVideoDetail, createVideo, deleteVideo, updateVideo, importVideos,
    cloneVideo, ignoreVideo, restoreTrainingVideos, changeVideoTitle, usersInVideo,
    shareVideo, shareVideoByLink, canAutoShareVideo, saveAutoShareVideo, shareVideos,
    markFavoriteVideo, quitFavoriteVideo, saveLastPlaying, deleteOrDismissVideos, restoreVideos, restoreVideo
};
