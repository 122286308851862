import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ListsSelector } from '../general/ListsSelector';
import { assingListsForVideo, assingListsForVideos } from '../services/VitagListsService';
import { ConfirmModal } from '../general/ConfirmModal';
import { useCustomToast } from '../utils/toasts/useCustomToast';

function ModalListsForVideos({
        vtgVideo, selectedVideoIds, showModal, setShowModal
    } ) {

    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [selecteListIds, setSelecteListIds] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const initModal = () => {
        console.log('Initializing modal');
        setSelecteListIds([]);
        setShowConfirmModal(false);
    }

    const saveSelectedLists = () => {
        if (vtgVideo?.videoId) {
            assingListsForVideo(vtgVideo.videoId, selecteListIds)
                .then(_ => success())
                .catch(err => showError());
        }
        if (selectedVideoIds?.length > 0) {
            assingListsForVideos(selectedVideoIds, selecteListIds)
            .then(_ => success())
            .catch(err => showError());
        }
    }

    const success = () => {
        closeThisModal();
        setShowConfirmModal(true);
    }

    const showError = () => addToast({
        header: t('modal_lists_for_video_error_titleattr'),
        body: t('modal_lists_for_video_error_bodyattr'),
        variant: 'error'    
    });

    const closeThisModal = () => setShowModal(false);

    return (<>
    <Modal show={showModal} onHide={closeThisModal} onShow={initModal} size="md">
        <Modal.Header>
            <button onClick={closeThisModal}
                title={t('general_close')}
                className="btn btn-sm text-secondary">
                <span className="material-icons-round">close</span>
            </button>
            <Modal.Title>{t('modal_lists_for_video_title')}</Modal.Title>
            <button onClick={saveSelectedLists}
                title={t('modal_lists_btn_savelistsforvideo_titleattr')}
                className="btn btn-sm text-vitag">
                <span className="material-icons-round">save</span>
            </button>
        </Modal.Header>
        <Modal.Body className='p-4'>
            { selectedVideoIds?.length > 0 && <p className="text-dark">
                {t('modal_lists_txt_selectlistforvideos', {n: selectedVideoIds.length})}
            </p> }
            { vtgVideo?.title && <p className="text-dark">
                {t('modal_lists_txt_selectlistforvideo', {video: vtgVideo?.title})}
            </p> }
            <ListsSelector
                vtgVideoId={vtgVideo?.videoId}
                videoIds={selectedVideoIds}
                selectedListIds={selecteListIds}
                setSelectedListIds={setSelecteListIds}
                allowEditLists={true}/>
        </Modal.Body>
    </Modal>
    <ConfirmModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        modalTitle = {t('modal_lists_for_video_success_titleattr')}
        modalText= {t('modal_lists_for_video_success_bodyattr')}/>
    </>);
}

export { ModalListsForVideos };